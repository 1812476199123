import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

// components
import TrackList from "@components/MusicSubmission/TrackList";
import Distribution from "@components/MusicSubmission/Distribution";
import ReleaseInfo from "@components/MusicSubmission/ReleaseInfo";
import ReleaseIntro from "@components/MusicSubmission/ReleaseIntro";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetMusicReleases } from "reducers/forms/musicReleasesFormReducer";
import { removeImageFromIndexedDB } from "@helpers/indexedDB/imageDB";

const StyledTabs = styled(Tabs)({
  marginTop: "1rem",
  marginBottom: "1rem",
  display: "flex",
  alignSelf: "center",
  alignItems: "center",
  justifySelf: "center",
  justifyContent: "center",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--accent)",
  },
  "& .MuiTabs-flexContainer": {
    "& button": {
      textTransform: "none",
      fontFamily: "var(--heading-font)",
      fontSize: "1rem",
      color: "var(--text-color)",
      "&.Mui-selected": {
        color: "var(--accent)",
      },
      "&.Mui-disabled": {
        opacity: 0.3,
      },
    },
  },
});

const MusicSubmissionContent = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleComplete = async () => {
    dispatch({ type: "RESET_ART_FORM" });
    dispatch(resetMusicReleases());
    await removeImageFromIndexedDB("artImageLink");
    navigate("/releases");
  };

  const handleNext = () => {
    if (tabIndex + 1 > currentStep) {
      setCurrentStep(tabIndex + 1);
    }
    setTabIndex(tabIndex + 1);
  };

  const handleBack = () => {
    if (tabIndex - 1 < currentStep) {
      setCurrentStep(tabIndex - 1);
    }
    setTabIndex(tabIndex - 1);
  };

  return (
    <>
      <p>Music Release</p>
      {tabIndex === 0 && <h4>Add your Copyright & Production Info</h4>}
      {tabIndex === 1 && <h4>Add your Release Info </h4>}
      {tabIndex === 2 && <h4>Upload your music files and add details</h4>}
      {tabIndex === 3 && <h4>Apply for a review and distribution</h4>}
      <div>
        <StyledTabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Intro" disabled={currentStep !== 0} />
          <Tab label="Release Information" disabled={currentStep !== 1} />
          <Tab label="Track List" disabled={currentStep !== 2} />
          <Tab label="Distribution" disabled={currentStep !== 3} />
        </StyledTabs>
      </div>
      <div className="raising-capital-section">
        {tabIndex === 0 && <ReleaseIntro onNext={handleNext} />}
        {tabIndex === 1 && (
          <ReleaseInfo onBack={handleBack} onNext={handleNext} />
        )}
        {tabIndex === 2 && (
          <TrackList onBack={handleBack} onNext={handleNext} />
        )}
        {tabIndex === 3 && (
          <Distribution onBack={handleBack} onComplete={handleComplete} />
        )}
      </div>
    </>
  );
};

export default MusicSubmissionContent;
