import {
  createArtMutation,
  retrieveUserArtist,
  createMusicReleaseMutation,
} from "@helpers/appsync/api";
import { useDispatch, useSelector } from "react-redux";
import { ReleaseStatus } from "models";
import { deleteFile } from "@helpers/indexedDB/songDB";

const useMusicDistribution = () => {
  const artForm = useSelector((state) => state.artForm);
  const musicReleasesForm = useSelector((state) => state.musicReleasesForm);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const uploadMusic = async () => {
    try {
      const {
        releaseDate,
        originalCoverImage,
        compressedCoverImage,
        productionHolder,
        productionYear,
        copyrightHolder,
        copyrightYear,
        title,
      } = artForm;

      const artist = await retrieveUserArtist(profile.id);
      const artArtistId = artist.id;

      const newArt = {
        artArtistId,
        title,
        compressedCoverImage,
        originalCoverImage,
      };
      const art = await createArtMutation(newArt);
      const artId = art.data.createArt.id;

      for (const musicRelease of musicReleasesForm) {
        const {
          serial,
          title,
          featureArtist,
          category,
          songWriter,
          lyrics,
          file,
          previewFile,
          isExplicit,
        } = musicRelease;
        const newMusicRelease = {
          musicReleaseArtId: artId,
          releaseDate,
          featureArtist,
          category,
          productionHolder,
          productionYear,
          copyrightHolder,
          copyrightYear,
          file,
          previewFile,
          isExplicit,
          songWriter,
          releaseStatus: ReleaseStatus.PENDING,
          lyrics,
          title,
        };
        await createMusicReleaseMutation(newMusicRelease);
        await deleteFile(`${title}-${serial}`);
      }
      const artistProfileToUpdate = { id: profile.id, isArtist: true };
      dispatch({ type: "UPDATE_PROFILE", payload: artistProfileToUpdate });
      return artId;
    } catch (e) {
      throw e;
    }
  };

  return { uploadMusic };
};

export default useMusicDistribution;
