const initialState = {
  id: "",
};

const clickedProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CLICKED_PROJECT_ID":
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};

export default clickedProjectReducer;
