import React, { useState } from "react";
import styles from "./style.module.scss";
import LazyImage from "@components/LazyImage";
import { useEffect } from "react";
import { Grid } from "@mui/material";

const LatestAlbum = ({ album }) => {
  const {
    image_url = "",
    name = "",
    release_date = "",
    spotify_album_ids = [],
    itunes_album_ids = [],
    deezer_album_ids = [],
  } = album;

  const getReleaseYear = () => {
    return new Date(release_date).getFullYear();
  };

  const handleSpotifyNavigate = () => {
    if (spotify_album_ids?.length > 0) {
      window.location.href = `https://open.spotify.com/album/${spotify_album_ids[0]}`;
    } else if (itunes_album_ids?.length > 0) {
      window.location.href = `https://music.apple.com/us/album/${itunes_album_ids[0]}`;
    } else if (deezer_album_ids?.length > 0) {
      window.location.href = `https://www.deezer.com/en/album/${deezer_album_ids[0]}`;
    }
  };

  return (
    <Grid
      className={styles.container}
      item
      sm={12}
      md={4}
      onClick={handleSpotifyNavigate}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <LazyImage
          style={{
            width: "10rem",
            height: "10rem",
          }}
          alt="Album Image"
          src={image_url}
        />
      </div>
      <div className={styles.block_details}>
        <h4 className="text-sm text-bold" style={{ marginTop: "0.4rem" }}>
          {name}
        </h4>
        <p className="text-xs text-light" style={{ opacity: 0.6 }}>
          {getReleaseYear()}
        </p>
      </div>
    </Grid>
  );
};

export default LatestAlbum;
