import { useState } from "react";
import DragAndDropSong from "./DragAndDropSong";
import {
  TextField,
  Autocomplete,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import styles from "./style.module.scss";
import { setMusicReleaseProperty } from "reducers/forms/musicReleasesFormReducer";

const categories = [
  "Afrobeats",
  "Hip-hop",
  "R&B",
  "Pop",
  "Electronic",
  "Rock",
  "Country",
  "Jazz",
  "Reggae",
  "Metal",
  "Classical",
  "Folk",
];

const TrackDetails = ({ musicRelease, coverTitle }) => {
  const {
    serial,
    title,
    featureArtist,
    songWriter,
    lyrics,
    category,
    file,
    isExplicit,
  } = musicRelease;

  const handleSong = async (fileUri, previewFileUri) => {
    dispatch(setMusicReleaseProperty("file", fileUri, serial));
    dispatch(setMusicReleaseProperty("previewFile", previewFileUri, serial));
  };

  const dispatch = useDispatch();

  const handleFeatureArtistChange = (event) => {
    dispatch(
      setMusicReleaseProperty("featureArtist", event.target.value, serial)
    );
  };

  const handleArtTitleChange = (event) => {
    dispatch(setMusicReleaseProperty("title", event.target.value, serial));
  };

  const handleCategoryChange = (newValue) => {
    dispatch(setMusicReleaseProperty("category", newValue, serial));
  };

  const handleLyricsChange = (event) => {
    dispatch(setMusicReleaseProperty("lyrics", event.target.value, serial));
  };

  const handleSongWriterChange = (event) => {
    dispatch(setMusicReleaseProperty("songWriter", event.target.value, serial));
  };

  const handleIsExplicitChange = (event, newValue) => {
    dispatch(setMusicReleaseProperty("isExplicit", newValue, serial));
  };

  return (
    <Grid
      container
      bgcolor={"#0000004c"}
      marginTop={"1rem"}
      padding={"1rem"}
      borderRadius={5}
      spacing={2}
      marginLeft={"auto"}
      marginRight={"auto"}
      style={{ width: "80%" }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <DragAndDropSong
          currentFileUri={file}
          coverTitle={coverTitle}
          currentTrackSerial={serial}
          onSong={handleSong}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <p className={styles.input_title}>Song Title:</p>
        <TextField
          placeholder="Song Title"
          value={title}
          onChange={handleArtTitleChange}
          required
          sx={{
            width: "100%",
            "& .MuiInputBase-input": { color: "white" },
            "& label": { color: "grey" },
            "& fieldset": { borderColor: "grey" },
            "&:hover fieldset": { borderColor: "grey" },
            "&.Mui-focused fieldset": { borderColor: "grey" },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <p className={styles.input_title}>Feature Artist:</p>
        <TextField
          placeholder="Feature Artist"
          value={featureArtist}
          onChange={handleFeatureArtistChange}
          sx={{
            width: "100%",
            "& .MuiInputBase-input": { color: "white" },
            "& label": { color: "grey" },
            "& fieldset": { borderColor: "grey" },
            "&:hover fieldset": { borderColor: "grey" },
            "&.Mui-focused fieldset": { borderColor: "grey" },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <p className={styles.input_title}>Song Category:</p>
        <Autocomplete
          sx={{
            width: "100%",
            "& .MuiInputBase-input": { color: "white" },
            "& label": { color: "grey" },
            "& fieldset": { borderColor: "grey" },
            "&:hover fieldset": { borderColor: "grey" },
            "&.Mui-focused fieldset": { borderColor: "grey" },
          }}
          options={categories}
          value={category}
          onChange={(event, newValue) => handleCategoryChange(newValue)}
          renderInput={(params) => (
            <TextField required {...params} placeholder="Press to choose" />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <p className={styles.input_title}>Song Writer:</p>
        <TextField
          placeholder="Song writer"
          value={songWriter}
          required
          onChange={handleSongWriterChange}
          sx={{
            width: "100%",
            "& .MuiInputBase-input": { color: "white" },
            "& label": { color: "grey" },
            "& fieldset": { borderColor: "grey" },
            "&:hover fieldset": { borderColor: "grey" },
            "&.Mui-focused fieldset": { borderColor: "grey" },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <p className={styles.input_title}>Lyrics:</p>
        <TextField
          placeholder="Enter lyrics"
          value={lyrics}
          multiline
          rows={2}
          required
          sx={{
            width: "100%",
            "& .MuiInputBase-input": { color: "white" },
            "& label": { color: "grey" },
            "& fieldset": { borderColor: "grey" },
            "&:hover fieldset": { borderColor: "grey" },
            "&.Mui-focused fieldset": { borderColor: "grey" },
          }}
          onChange={handleLyricsChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isExplicit}
              onChange={handleIsExplicitChange}
              color="primary"
            />
          }
          label={
            <p
              style={{
                opacity: 0.8,
                fontSize: "0.85rem",
                margin: 0,
                padding: 0,
                fontWeight: "lighter",
              }}
            >
              By pressing here, you confirm that this track contains explicit
              content.
            </p>
          }
        />
      </Grid>
    </Grid>
  );
};

export default TrackDetails;
