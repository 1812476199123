import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AlbumIcon from "@mui/icons-material/Album";
import MovingIcon from "@mui/icons-material/Moving";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, ListItemAvatar, IconButton, Button } from "@mui/material";
import Avatar from "@ui/Avatar";
import { getImageFromS3 } from "@helpers/appsync/storage";
import placeholder from "@assets/avatar_placeholder.webp";
import useFileReader from "@hooks/useFileReader";
import {
  RegistrationPhase,
  useRegistrationModalContext,
} from "@contexts/registrationModalContext";
import Logo from "./Logo";
import {
  ShoppingBag,
  Menu as MenuIcon,
  HomeRounded,
  AccountBalanceWalletOutlined,
} from "@mui/icons-material";

const drawerWidth = 240;

const isMobileDevice = (threshold = 768) => window.innerWidth < threshold;

const DrawerDashboardLayout = ({ children }) => {
  const [isPhoneDrawerOpen, setIsPhoneDrawerOpen] = useState(false);
  const [isArtist, setIsArtist] = useState(false);
  const profile = useSelector((state) => state.profile);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const { file, setFile, loading } = useFileReader();
  const { openRegistrationModal } = useRegistrationModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    setIsArtist(profile?.id && profile?.isArtist);
  }, [profile?.isArtist]);

  useEffect(() => {
    const fullRoutes = [
      {
        id: 1,
        name: "Releases",
        link: "/releases",
        icon: <AlbumIcon sx={{ color: "#B7B7B7" }} />,
      },
      {
        id: 2,
        name: "Fundraising",
        link: "/fundraising",
        icon: <MovingIcon sx={{ color: "#B7B7B7" }} />,
      },
      {
        id: 3,
        name: "Holdings",
        link: "/holdings",
        icon: <ShoppingBag sx={{ color: "#B7B7B7" }} />,
      },
      {
        id: 4,
        name: "Wallet",
        link: "/wallet",
        icon: <AccountBalanceWalletOutlined sx={{ color: "#B7B7B7" }} />,
      },
    ];
    setFilteredRoutes(fullRoutes);
    const currentPathname = window.location.pathname;
    const selectedRoute = fullRoutes.find(
      (route) => route.link === currentPathname
    );
    setSelectedRoute(selectedRoute);
    if (!isArtist) {
      const nonArtistRoutes = [
        {
          id: 1,
          name: "Holdings",
          link: "/holdings",
          icon: <ShoppingBag sx={{ color: "#B7B7B7" }} />,
        },
        {
          id: 2,
          name: "Wallet",
          link: "/wallet",
          icon: <AccountBalanceWalletOutlined sx={{ color: "#B7B7B7" }} />,
        },
      ];
      setFilteredRoutes(nonArtistRoutes);
      const nonArtistSelectedRoute = nonArtistRoutes.find(
        (route) => route.link === currentPathname
      );
      setSelectedRoute(nonArtistSelectedRoute);
    }
  }, [isArtist]);

  useEffect(() => {
    if (profile?.id) {
      if (!profile?.username || !profile?.username?.length === 0) {
        openRegistrationModal(RegistrationPhase.USERNAME);
      } else if (!profile?.referrer || !profile?.referrer?.length === 0) {
        openRegistrationModal(RegistrationPhase.REFERRER);
      }
    }
  }, [profile]);

  const navigateToHome = () => {
    navigate("/");
  };

  const fetchProfileImage = async () => {
    try {
      if (profile.profileImage && profile.profileImage !== "") {
        const profileProfileImage = await getImageFromS3(profile.profileImage);
        setFile(profileProfileImage);
      }
    } catch (err) {
      console.log("Can't Show Profile Image");
    }
  };

  const setPlaceholder = () => setFile(placeholder);

  useEffect(() => {
    setPlaceholder();
    fetchProfileImage();
  }, []);

  const handleRouteClick = (route) => {
    setSelectedRoute(route);
    if (isPhoneDrawerOpen) {
      togglePhoneDrawer();
    }
  };

  const togglePhoneDrawer = () => {
    setIsPhoneDrawerOpen(!isPhoneDrawerOpen);
  };

  const drawer = (
    <div style={{ marginTop: "10%" }}>
      <div style={{ marginLeft: "10%" }}>
        <Logo />
      </div>
      <List>
        {filteredRoutes.map((route, index) => (
          <Link
            to={route.link}
            style={{ textDecoration: "none" }}
            key={route.id}
          >
            <ListItem
              disablePadding
              sx={{
                background:
                  selectedRoute === route
                    ? `linear-gradient(to left, rgba(128, 128, 128, 0.06), rgba(255, 255, 255, 0.1))`
                    : "none",
                "&:hover": {
                  background: `linear-gradient(to left, rgba(128, 128, 128, 0.06), rgba(255, 255, 255, 0.15))`,
                },
                borderRight:
                  selectedRoute === route ? "5px solid #0DED5C" : "none",
                marginTop: "10px",
              }}
              key={route.id}
            >
              <ListItemButton
                sx={{
                  fontFamily: "var(--heading-font)",
                }}
                disableRipple
                onClick={() => handleRouteClick(route)}
              >
                <ListItemIcon>{route.icon}</ListItemIcon>
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontFamily: "var(--heading-font)",
                  }}
                >
                  {route.name}
                </p>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <ListItem
        disablePadding
        sx={{
          position: "absolute",
          "&:hover": {
            background: `linear-gradient(to left, rgba(128, 128, 128, 0.06), rgba(255, 255, 255, 0.15))`,
          },
          bottom: "5%", // Push the profile item to the bottom
        }}
      >
        <Link to="/profile" style={{ textDecoration: "none" }}>
          <ListItemButton disableRipple>
            <ListItemAvatar
              children={
                <Avatar
                  size={"sm"}
                  src={file ? file : placeholder}
                  alt=""
                  isVerified={isArtist ? true : false}
                />
              }
            ></ListItemAvatar>
            <ListItemText>
              <p style={{ fontSize: "0.8rem" }}>
                {profile.name || "@" + profile.username}
              </p>
              <p style={{ opacity: 0.5 }}>{isArtist ? "Artist" : "User"}</p>
            </ListItemText>
          </ListItemButton>
        </Link>
      </ListItem>
    </div>
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ marginLeft: "1rem" }}
          size="large"
          color="inherit"
          onClick={togglePhoneDrawer}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Button
          sx={{
            fontFamily: "var(--heading-font)",
            color: "white",
            margin: "1rem",
          }}
          onClick={navigateToHome}
          style={{ textTransform: "none" }}
          variant="text"
          startIcon={
            <HomeRounded
              sx={{
                marginLeft: "1rem",
              }}
              fontSize="inherit"
            />
          }
        >
          Home
        </Button>
      </Box>

      {/* Drawer for large screens */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          display: { xs: "none", sm: "block" },
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            xs: "100%", // Full width on small screens
            sm: `calc(100% - ${drawerWidth}px)`, // Subtract drawerWidth on larger screens
          },
          marginLeft: {
            sm: `${drawerWidth}px`, // Add left margin for the drawer on larger screens
          },
        }}
      >
        {children}
      </Box>

      {/* Drawer for small screens */}
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "black",
            color: "white",
          },
        }}
        anchor="left"
        open={isPhoneDrawerOpen}
        onClose={togglePhoneDrawer}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default DrawerDashboardLayout;
