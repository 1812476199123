import { useCallback, useState } from "react";
import GradientBtn from "@ui/GradientBtn";
import {
  Add,
  ArrowBackIosNewTwoTone,
  CloseFullscreenOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Grid, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TrackHeader from "./components/TrackHeader";
import TrackDetails from "./components/TrackDetails";
import { toast } from "react-toastify";
import { getImageFromIndexedDB } from "@helpers/indexedDB/imageDB";
import LazyImage from "@components/LazyImage";
import { useEffect } from "react";
import styles from "./style.module.scss";
import {
  initNewMusicRelease,
  resetMusicReleases,
} from "reducers/forms/musicReleasesFormReducer";
import { MusicType } from "@constants/strings";
import { deleteFile } from "@helpers/indexedDB/songDB";

const TrackList = ({ onNext, onBack }) => {
  const dispatch = useDispatch();

  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const musicReleasesForm = useSelector((state) => state.musicReleasesForm);
  const [musicReleases, setMusicReleases] = useState([]);
  const artForm = useSelector((state) => state.artForm);
  const [artImage, setArtImage] = useState(null);
  const profile = useSelector((state) => state.profile);

  const {
    title: coverTitle,
    productionHolder,
    productionYear,
    copyrightHolder,
    copyrightYear,
  } = artForm;

  useEffect(() => {
    if (artForm.musicType === MusicType.SINGLE) {
      setMusicReleases(musicReleasesForm.slice(0, 1));
    } else {
      setMusicReleases(musicReleasesForm);
    }
  }, [musicReleasesForm]);

  const addNewTrack = () => {
    setCurrentTrackIndex(musicReleases.length); // insert index to be the last of musicReleases before init new music release
    dispatch(initNewMusicRelease()); // init new music release
  };

  const minimizeAllTracks = () => {
    // when minimize, set currentTrackIndex to -1, that way all music releases will be minimized tracks
    setCurrentTrackIndex(-1);
  };

  const handleNextClick = () => {
    const isEveryItemFilled = musicReleases.every(
      (currentMusicRelease) =>
        currentMusicRelease.lyrics !== "" &&
        currentMusicRelease.title !== "" &&
        currentMusicRelease.songWriter !== "" &&
        currentMusicRelease.file !== "" &&
        currentMusicRelease.category !== ""
    );
    if (!isEveryItemFilled) {
      toast.error("Please fill all the fields and ensure valid input.");
    } else {
      onNext();
    }
  };

  const moveTrack = (serial) => {
    if (currentTrackIndex === serial - 1) {
      minimizeAllTracks();
    } else {
      setCurrentTrackIndex(serial - 1); // index => serial - 1
    }
  };

  const removeAllTracks = async () => {
    for (const musicRelease of musicReleases) {
      await deleteFile(`${coverTitle}-${musicRelease.serial}`);
    }
    dispatch(resetMusicReleases());
    setCurrentTrackIndex(-1);
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const result = await getImageFromIndexedDB("artImageLink");
        if (result && result.data) {
          setArtImage(result.data);
        }
      } catch (error) {
        console.log("Failed to open IndexedDB:", error);
      }
    };

    fetchImage();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} marginTop={1} marginBottom={1}>
        <Button
          onClick={onBack}
          sx={{ fontFamily: "var(--heading-font)", textTransform: "none" }}
          startIcon={<ArrowBackIosNewTwoTone />}
          color="primary"
        >
          Go Back
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container style={{ width: "80%", margin: "auto" }}>
          {artImage && (
            <Grid item xs={12} sm={12} md={3}>
              <LazyImage
                className={styles.cover}
                src={artImage}
                alt="uploaded"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={9}>
            <h6 style={{ opacity: 0.5 }}>{artForm.musicType}</h6>
            <h1>{coverTitle}</h1>
            <h5>{profile.username}</h5>
            <p style={{ color: "white", fontSize: "1rem" }}>
              <span style={{ opacity: 0.7, fontSize: "smaller" }}>
                Copyright:
              </span>
              {` © ${copyrightHolder}
               | ${copyrightYear}`}
            </p>
            <p style={{ color: "white", fontSize: "1rem" }}>
              <span style={{ opacity: 0.7, fontSize: "smaller" }}>
                Production:
              </span>
              {` © ${productionHolder}
               | ${productionYear}`}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            marginTop={"10px"}
            alignItems={"center"}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton onClick={minimizeAllTracks}>
              <CloseFullscreenOutlined color="secondary" />
            </IconButton>
          </Grid>
          {artForm?.musicType !== MusicType.SINGLE ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              marginTop={1}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Button
                size="small"
                variant="outlined"
                color="error"
                sx={{
                  fontFamily: "var(--heading-font)",
                  textTransform: "none",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                startIcon={<DeleteOutline />}
                onClick={removeAllTracks}
              >
                Delete All Tracks
              </Button>
              <div>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "var(--heading-font)",
                    textTransform: "none",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<Add />}
                  onClick={addNewTrack}
                >
                  Add New Track
                </Button>
              </div>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              marginTop={5}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Button
                size="small"
                variant="outlined"
                color="error"
                sx={{
                  fontFamily: "var(--heading-font)",
                  textTransform: "none",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                startIcon={<DeleteOutline />}
                onClick={removeAllTracks}
              >
                Reset Track
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {musicReleases.map((musicRelease) =>
        currentTrackIndex === musicRelease.serial - 1 ? (
          <>
            <TrackHeader
              isOpen={true}
              key={musicRelease.serial}
              musicRelease={musicRelease}
              onTrackClick={moveTrack}
            />
            <TrackDetails
              key={musicRelease.serial}
              musicRelease={musicRelease}
              coverTitle={coverTitle}
            />
          </>
        ) : (
          <TrackHeader
            key={musicRelease.serial}
            musicRelease={musicRelease}
            onTrackClick={moveTrack}
          />
        )
      )}
      <Grid item xs={12} sm={12} md={12}>
        <GradientBtn onClick={handleNextClick}>Complete</GradientBtn>
      </Grid>
    </Grid>
  );
};

export default TrackList;
