import { Magic } from "magic-sdk";
import { HederaExtension } from "@magic-ext/hedera";
import {
  TransferTransaction,
  TokenId,
  TokenAssociateTransaction,
} from "@hashgraph/sdk";
import { USDC_DECIMAL_NUM } from "@constants/numbers";
import { MagicProvider } from "@helpers/magic/MagicProvider";
import { MagicWallet } from "@helpers/magic/MagicWallet";
import {
  isNftAssociatedToAccount,
  getLatestTransactionStatus,
} from "@helpers/api/hedera";
import { loginUser } from "@helpers/hedera/auth";
import { toast } from "react-toastify";

const magicApiKey = process.env.REACT_APP_MAGIC_API_KEY || "";
const currEnv = process.env.REACT_APP_ENV;

const magic = new Magic(magicApiKey, {
  extensions: [
    new HederaExtension({
      network: currEnv,
    }),
  ],
});

const useMagicWallet = () => {
  const verifyMagicLogin = (
    dispatch,
    email = "",
    setLoading,
    setIsLoggedIn
  ) => {
    magic.user.isLoggedIn().then(async (magicIsLoggedIn) => {
      try {
        if (magicIsLoggedIn) {
          setLoading({ isShown: true, message: "Connecting" });
          const info = await magic.user.getInfo();
          const { publicAddress } = info;
          const result = await magic.hedera.sign(
            new TextEncoder().encode(publicAddress)
          );
          const signature = new TextDecoder().decode(result);
          await loginUser(
            publicAddress,
            dispatch,
            signature,
            setLoading,
            email
          );
          dispatch({ type: "SET_WALLET_TYPE", payload: "Magic" });
        }
      } catch (error) {
        setIsLoggedIn(false);
        toast.error("Failed to login");
      } finally {
        setLoading({ isShown: false, message: "" });
      }
    });
  };

  const emailLogin = async (email) => {
    await magic.auth.loginWithEmailOTP({ email });
  };

  const emailLogout = async () => {
    await magic.user.logout();
  };

  const associateNftsViaMagicWallet = async (accountId, setLoading) => {
    try {
      setLoading({
        isShown: true,
        message: "Associating NFTs",
      });
      const { publicKeyDer } = await magic.hedera.getPublicKey();

      const tokenId = process.env.REACT_APP_NFT_TOKEN_ID;
      const magicSign = (message) => magic.hedera.sign(message);
      const magicWallet = new MagicWallet(
        accountId,
        new MagicProvider(currEnv),
        publicKeyDer,
        magicSign
      );

      let transaction = await new TokenAssociateTransaction()
        .setAccountId(magicWallet.accountId)
        .setTokenIds([TokenId.fromString(tokenId)])
        .freezeWithSigner(magicWallet);

      transaction = await transaction.signWithSigner(magicWallet);
      const txResult = await transaction.executeWithSigner(magicWallet);
      return txResult.transactionId;
    } catch (err) {
      throw err;
    } finally {
      setLoading({ isShown: false, message: "" });
    }
  };

  const associateUsdcViaMagicWallet = async (accountId) => {
    try {
      const { publicKeyDer } = await magic.hedera.getPublicKey();

      const tokenId = process.env.REACT_APP_USDC_TOKEN_ID;
      const magicSign = (message) => magic.hedera.sign(message);
      const magicWallet = new MagicWallet(
        accountId,
        new MagicProvider(currEnv),
        publicKeyDer,
        magicSign
      );

      let transaction = await new TokenAssociateTransaction()
        .setAccountId(magicWallet.accountId)
        .setTokenIds([TokenId.fromString(tokenId)])
        .freezeWithSigner(magicWallet);

      transaction = await transaction.signWithSigner(magicWallet);
      const txResult = await transaction.executeWithSigner(magicWallet);
      return txResult.transactionId;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const payWithMagicWallet = async (accountId, mintAmount, setLoading) => {
    try {
      const isNftAssociated = await isNftAssociatedToAccount(accountId);
      if (!isNftAssociated) {
        setLoading({
          isShown: true,
          message: "Waiting For Signing Transfer",
        });
        const associateNftReceipt = await associateNftsViaMagicWallet(
          accountId,
          setLoading
        );
        if (!associateNftReceipt) {
          toast.error("NFT Association Failed!");
          return;
        }
      }

      handleMagicTransaction();

      const investmentEscrowWallet =
        process.env.REACT_APP_INVESTMENT_ACCOUNT_ID;
      setLoading({
        isShown: true,
        message: "Waiting For Signing Transfer",
      });
      const transaction = await handleMagicTransaction(
        accountId,
        mintAmount,
        investmentEscrowWallet
      );
      return transaction;
    } catch (err) {
      throw err;
    }
  };

  const handleMagicTransaction = async (
    accountId,
    sendAmount,
    destinationAddress
  ) => {
    const { publicKeyDer } = await magic.hedera.getPublicKey();
    const usdc = process.env.REACT_APP_USDC_TOKEN_ID;

    const magicSign = (message) => magic.hedera.sign(message);
    const magicWallet = new MagicWallet(
      accountId,
      new MagicProvider(currEnv),
      publicKeyDer,
      magicSign
    );

    let transaction = await new TransferTransaction()
      .addTokenTransfer(usdc, accountId, -1 * sendAmount * USDC_DECIMAL_NUM)
      .addTokenTransfer(usdc, destinationAddress, sendAmount * USDC_DECIMAL_NUM)
      .freezeWithSigner(magicWallet);

    transaction = await transaction.signWithSigner(magicWallet);
    const transferTokenTx = await transaction.executeWithSigner(magicWallet);
    const transactionStatus = await getLatestTransactionStatus(accountId);
    if (transferTokenTx?.transactionId && transactionStatus === "SUCCESS") {
      return transferTokenTx.transactionId.toString();
    } else {
      return null;
    }
  };
  return {
    verifyMagicLogin,
    emailLogin,
    emailLogout,
    payWithMagicWallet,
    handleMagicTransaction,
    associateUsdcViaMagicWallet,
  };
};

export default useMagicWallet;
