import Database, { STORE_SONG_NAME } from "./singleton-db";

// Function to encode a file as a base64 string
const encodeFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// Function to decode a base64-encoded file and return the original file object
const decodeFile = (base64String, fileName, mimeType) => {
  const binaryString = Buffer.from(base64String, "base64");
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.at(i);
  }
  const blob = new Blob([bytes], { type: mimeType }); // Adjust MIME type based on the type of file
  const file = new File([blob], fileName);
  return file;
};

/**
 * Store a file in IndexedDB.
 *
 * @param {string} key - The key to use when storing the file.
 * @param {File} file - The file to store.
 * @returns {Promise<void>} A promise that resolves when the file is stored.
 */
const storeFile = async (key, file) => {
  const encodedFile = await encodeFile(file);
  console.log("saving with file type of: " + file.type);
  try {
    const db = await Database.open();
    const tx = db.transaction(STORE_SONG_NAME, "readwrite");
    const store = tx.objectStore(STORE_SONG_NAME);
    const request = store.put({
      id: key,
      name: file.name,
      data: encodedFile,
      type: file.type, // Store the MIME type
    });

    return new Promise((resolve, reject) => {
      request.onerror = () => {
        reject(Error("Failed to store file in IndexedDB"));
      };

      request.onsuccess = () => {
        const result = request.result;
        resolve(result);
      };
    });
  } catch (error) {
    console.log("Failed to open IndexedDB:", error);
  }
};

/**
 * Get a file from IndexedDB and decode it into a File object.
 *
 * @param {string} key - The key under which the file is stored in IndexedDB.
 * @returns {Promise<File>} A promise that resolves with the decoded File.
 */
const getFile = async (key) => {
  try {
    const db = await Database.open();
    const tx = db.transaction(STORE_SONG_NAME, "readwrite");
    const store = tx.objectStore(STORE_SONG_NAME);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onerror = () => {
        reject(Error("Failed to get file in IndexedDB"));
      };

      request.onsuccess = () => {
        const result = request.result;
        if (result) {
          const decodedFile = decodeFile(result.data, result.name, result.type);
          resolve(decodedFile);
        }
      };
    });
  } catch (error) {
    console.log("Failed to open IndexedDB:", error);
  }
};

/**
 * Delete a file from IndexedDB.
 *
 * @param {string} key - The key under which the file is stored in IndexedDB.
 * @returns {Promise<void>} A promise that resolves when the file is deleted.
 */
const deleteFile = async (key) => {
  try {
    const db = await Database.open();
    const tx = db.transaction(STORE_SONG_NAME, "readwrite");
    const store = tx.objectStore(STORE_SONG_NAME);
    const request = store.delete(key);

    return new Promise((resolve, reject) => {
      request.onerror = () => {
        reject(Error("Failed to delete file from IndexedDB"));
      };

      request.onsuccess = () => {
        resolve();
      };
    });
  } catch (error) {
    console.log("Failed to open IndexedDB:", error);
  }
};

export { getFile, storeFile, deleteFile };
