import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "@fonts/icomoon/icomoon.woff";
import { Provider } from "react-redux";
import store from "./stores/store";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        style={{ zIndex: "9999 !important" }}
        toastStyle={{ zIndex: "9999 !important" }}
        theme="dark"
        autoClose={3000}
      />
    </BrowserRouter>
  </Provider>
);
