import {
  TextField,
  Autocomplete,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import GradientBtn from "@ui/GradientBtn";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
import { useState } from "react";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";

const ReleaseIntro = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const [isChecked, setChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Create an array to store the list of objects
  const yearOptions = [];

  // Populate the array with objects from 1970 to the next year
  for (let year = currentYear + 1; year >= 1970; year--) {
    yearOptions.push(year);
  }

  const artForm = useSelector((state) => state.artForm);

  const { copyrightHolder, productionHolder, copyrightYear, productionYear } =
    artForm;

  const handleCopyrightHolderChange = (event) => {
    dispatch({
      type: "SET_ART_COPYRIGHT_HOLDER_FORM",
      payload: event.target.value,
    });
  };

  const handleCopyrightYearChange = (newValue) => {
    dispatch({
      type: "SET_ART_COPYRIGHT_YEAR_FORM",
      payload: newValue,
    });
  };

  const handleProductionYearChange = (newValue) => {
    dispatch({
      type: "SET_ART_PRODUCTION_YEAR_FORM",
      payload: newValue,
    });
  };

  const handleProductionHolderChange = (event) => {
    dispatch({
      type: "SET_ART_PRODUCTION_HOLDER_FORM",
      payload: event.target.value,
    });
  };

  const handleNextClick = () => {
    if (
      copyrightHolder === "" ||
      copyrightYear === "" ||
      productionHolder === "" ||
      productionYear === ""
    ) {
      toast.error("Please fill all the fields and ensure valid input.");
    } else {
      onNext();
    }
  };

  return (
    <Grid container spacing={3}>
      {onBack && (
        <Grid item xs={12} sm={12} md={12} marginTop={1} marginBottom={1}>
          <Button
            onClick={onBack}
            sx={{ fontFamily: "var(--heading-font)", textTransform: "none" }}
            startIcon={<ArrowBackIosNewOutlined />}
            color="primary"
          >
            Go Back
          </Button>
        </Grid>
      )}
      <Grid container spacing={1} style={{ width: "80%", margin: "auto" }}>
        <Grid item xs={12} sm={12} md={6}>
          <p className={styles.input_title}>Copyright Holder:</p>
          <TextField
            placeholder="Enter name"
            value={copyrightHolder}
            onChange={handleCopyrightHolderChange}
            sx={{
              width: "100%",
              "& .MuiInputBase-input": { color: "white" },
              "& label": { color: "grey" },
              "& fieldset": { borderColor: "grey" },
              "&:hover fieldset": { borderColor: "grey" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <p className={styles.input_title}>Copyright Year:</p>
          <Autocomplete
            sx={{
              width: "100%",
              "& .MuiInputBase-input": { color: "white" },
              "& label": { color: "grey" },
              "& fieldset": { borderColor: "grey" },
              "&:hover fieldset": { borderColor: "grey" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            }}
            options={yearOptions}
            value={copyrightYear}
            onChange={(event, newValue) => handleCopyrightYearChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Press to choose" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <p className={styles.input_title}>Production Holder:</p>
          <TextField
            placeholder="Enter name"
            value={productionHolder}
            onChange={handleProductionHolderChange}
            sx={{
              width: "100%",
              "& .MuiInputBase-input": { color: "white" },
              "& label": { color: "grey" },
              "& fieldset": { borderColor: "grey" },
              "&:hover fieldset": { borderColor: "grey" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <p className={styles.input_title}>Production Year:</p>
          <Autocomplete
            sx={{
              width: "100%",
              "& .MuiInputBase-input": { color: "white" },
              "& label": { color: "grey" },
              "& fieldset": { borderColor: "grey" },
              "&:hover fieldset": { borderColor: "grey" },
              "&.Mui-focused fieldset": { borderColor: "grey" },
            }}
            options={yearOptions}
            value={productionYear}
            onChange={(event, newValue) => handleProductionYearChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Press to choose" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} marginTop={5} marginBottom={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary" // Choose the color you prefer
              />
            }
            label={
              <p
                style={{
                  opacity: 0.8,
                  fontSize: "0.65rem",
                  margin: 0,
                  padding: 0,
                  fontWeight: "lighter",
                }}
              >
                By approving this information, you confirm that you own the
                rights to the music and any associated content. You grant the
                right to use, distribute, and license the music in accordance
                with the terms of our agreement. This includes copyright,
                production, and any related intellectual property rights.
              </p>
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <GradientBtn
          disabled={
            !(
              isChecked &&
              copyrightHolder !== "" &&
              copyrightYear &&
              productionHolder !== "" &&
              productionYear
            )
          }
          onClick={handleNextClick}
        >
          Continue
        </GradientBtn>
      </Grid>
    </Grid>
  );
};

export default ReleaseIntro;
