// styled components
import StyledUserCard from "./style";

// components
import Socials from "@components/Socials";
import Avatar from "@ui/Avatar";
import CollapsedText from "@components/CollapsedText";
import Spring from "@components/Spring";

// hooks
import { useState } from "react";
import { useWindowSize } from "react-use";
import useMeasure from "react-use-measure";

// utils
import { toast } from "react-toastify";
import { truncateMiddle } from "@utils/helpers";
import classNames from "classnames";
import placeholder from "@assets/avatar_placeholder.webp";
import bgBlack from "@assets/black_background.png";

// assets
import avatar from "@assets/avatar.webp";
import { getImageFromS3 } from "@helpers/appsync/storage";
import { useEffect } from "react";
import { Verified } from "@mui/icons-material";
import { isUserArtist } from "@helpers/appsync/api";
import { useNavigate } from "react-router-dom";

const UserCard = ({ user }) => {
  const { id, bio, coverImage, profileImage, username, name } = user;
  const [profileImageFile, setProfileImageFile] = useState(placeholder);
  const [coverImageFile, setCoverImageFile] = useState(bgBlack);
  const [isArtist, setIsArtist] = useState(true);
  const [ref, { width }] = useMeasure();
  const navigate = useNavigate();

  const fetchProfileImage = async () => {
    try {
      if (profileImage && profileImage.length > 0) {
        const profileProfileImage = await getImageFromS3(profileImage);
        setProfileImageFile(profileProfileImage);
      } else {
        setProfileImageFile(placeholder);
      }
    } catch (err) {
      setProfileImageFile(placeholder);
    }
  };

  const fetchCoverImage = async () => {
    try {
      if (profileImage && profileImage.length > 0) {
        const fetchedCoverImage = await getImageFromS3(coverImage);
        setCoverImageFile(fetchedCoverImage);
      } else {
        setCoverImageFile(bgBlack);
      }
    } catch (err) {
      setCoverImageFile(bgBlack);
    }
  };

  const checkIfUserIsArtist = async () => {
    const fetchedIsArtist = await isUserArtist(id);
    if (fetchedIsArtist) {
      navigate(`/artist/${username}`);
    } else {
      setIsArtist(false);
    }
  };

  useEffect(() => {
    checkIfUserIsArtist();
    fetchProfileImage();
    fetchCoverImage();
  }, []);

  if (isArtist) {
    return <></>;
  }

  return (
    <StyledUserCard coverImage={coverImageFile}>
      <div className="cover"></div>
      <div className="container">
        <Spring className="main d-flex flex-column g-30 bg-primary border-10">
          <div className="main_header d-flex">
            <Avatar src={profileImageFile} alt={`${name}`} isVerified={false} />
          </div>
          <div className="main_info">
            <div className="main_info d-flex flex-wrap align-items-center">
              <h4 className="main_info-name">{name}</h4>
            </div>
            <div className="main_info d-flex flex-wrap align-items-center text-sm">
              <span className="text-bold text-light">@{username}</span>
            </div>
            <div className="main_info-bio" ref={ref}>
              <CollapsedText width={width} text={bio} lines={3} withButton />
            </div>
          </div>
        </Spring>
      </div>
    </StyledUserCard>
  );
};

export default UserCard;
