/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByPublicAddress = /* GraphQL */ `
  query UsersByPublicAddress(
    $publicAddress: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByPublicAddress(
      publicAddress: $publicAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByUsername = /* GraphQL */ `
  query UsersByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNftMetadata = /* GraphQL */ `
  query GetNftMetadata($id: ID!) {
    getNftMetadata(id: $id) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const listNftMetadata = /* GraphQL */ `
  query ListNftMetadata(
    $filter: ModelNftMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNftMetadata(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        compressedImage
        rarity
        createdAt
        updatedAt
        nftMetadataProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArtist = /* GraphQL */ `
  query GetArtist($id: ID!) {
    getArtist(id: $id) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const listArtists = /* GraphQL */ `
  query ListArtists(
    $filter: ModelArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHolder = /* GraphQL */ `
  query GetHolder($id: ID!) {
    getHolder(id: $id) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const listHolders = /* GraphQL */ `
  query ListHolders(
    $filter: ModelHolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArt = /* GraphQL */ `
  query GetArt($id: ID!) {
    getArt(id: $id) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const listArts = /* GraphQL */ `
  query ListArts(
    $filter: ModelArtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMusicRelease = /* GraphQL */ `
  query GetMusicRelease($id: ID!) {
    getMusicRelease(id: $id) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const listMusicReleases = /* GraphQL */ `
  query ListMusicReleases(
    $filter: ModelMusicReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMusicReleases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMusicRoyalty = /* GraphQL */ `
  query GetMusicRoyalty($id: ID!) {
    getMusicRoyalty(id: $id) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const listMusicRoyalties = /* GraphQL */ `
  query ListMusicRoyalties(
    $filter: ModelMusicRoyaltyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMusicRoyalties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platform
        amountGenerated
        date
        createdAt
        updatedAt
        musicRoyaltyMusicReleaseId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHolding = /* GraphQL */ `
  query GetHolding($id: ID!) {
    getHolding(id: $id) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const listHoldings = /* GraphQL */ `
  query ListHoldings(
    $filter: ModelHoldingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHoldings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDistributionTransaction = /* GraphQL */ `
  query GetDistributionTransaction($id: ID!) {
    getDistributionTransaction(id: $id) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
export const listDistributionTransactions = /* GraphQL */ `
  query ListDistributionTransactions(
    $filter: ModelDistributionTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributionTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        amount
        isWithdrawn
        dateOfWithdrawal
        createdAt
        updatedAt
        distributionTransactionHoldingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
