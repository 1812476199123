// components
import SectionHeader from "@components/SectionHeader";
import Title from "@components/SEO";
import MusicSubmissionContent from "@layout/music-submission";
const MusicSubmission = () => {
  return (
    <>
      <Title title="Music Distribution" />
      <main>
        <div className="section">
          <div className="container">
            <SectionHeader title="Music Distribution " />
            <MusicSubmissionContent />
          </div>
        </div>
      </main>
    </>
  );
};

export default MusicSubmission;
