// styled components
import StyledArtistCard from "./style";

// components
import Socials from "@components/Socials";
import Avatar from "@ui/Avatar";
import CollapsedText from "@components/CollapsedText";
import Spring from "@components/Spring";

// hooks
import { useState } from "react";
import useMeasure from "react-use-measure";

// utils
import placeholder from "@assets/avatar_placeholder.webp";
import bgBlack from "@assets/black_background.png";

// assets
import avatar from "@assets/avatar.webp";
import { getImageFromS3 } from "@helpers/appsync/storage";
import { useEffect } from "react";
import { DarkModeOutlined, Verified } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import {
  FaApple,
  FaDeezer,
  FaInstagram,
  FaMusic,
  FaSoundcloud,
  FaSpotify,
  FaYoutube,
  FaYoutubeSquare,
} from "react-icons/fa";
import LoadingIndicator from "@components/LoadingIndicator/LoadingIndicator";
import useChartmetric from "@hooks/useChartmetric";

const ArtistCard = ({ artist }) => {
  const { bio = "", coverImage, profileImage, username, name = "" } = artist;
  const [profileImageFile, setProfileImageFile] = useState(placeholder);
  const [coverImageFile, setCoverImageFile] = useState(bgBlack);
  const [ref, { width }] = useMeasure();
  const { getUrlsByArtistName } = useChartmetric();
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    const getArtist = async () => {
      try {
        const fetchedObjectUrls = await getUrlsByArtistName(artist?.name);
        const filteredUrls = fetchedObjectUrls
          .map((item) => item.url)
          .flat()
          .reverse();
        setUrls(filteredUrls);
      } catch (err) {
        const initUrls = [
          "https://open.spotify.com/search/" +
            (name?.length > 0 ? name : username),
          "https://music.youtube.com/search?q=" +
            (name?.length > 0 ? name : username),
          "https://soundcloud.com/search?q=" +
            (name?.length > 0 ? name : username),
          "https://www.deezer.com/search/" +
            (name?.length > 0 ? name : username),
          "https://music.apple.com/us/search?term=" +
            (name?.length > 0 ? name : username),
        ];
        setUrls(initUrls);
      }
    };

    getArtist();
  }, [artist]);

  const navigateToPlatform = (platform) => {
    if (urls?.length > 0) {
      const selectedUrl = urls.find((url) => url.includes(platform));
      window.location.href = selectedUrl;
    }
  };

  const fetchProfileImage = async () => {
    try {
      if (profileImage && profileImage.length > 0) {
        const profileProfileImage = await getImageFromS3(profileImage);
        setProfileImageFile(profileProfileImage);
      } else {
        setProfileImageFile(placeholder);
      }
    } catch (err) {
      setProfileImageFile(placeholder);
    }
  };

  const fetchCoverImage = async () => {
    try {
      if (coverImage && coverImage.length > 0) {
        const fetchedCoverImage = await getImageFromS3(coverImage);
        setCoverImageFile(fetchedCoverImage);
      } else {
        setCoverImageFile(bgBlack);
      }
    } catch (err) {
      setCoverImageFile(bgBlack);
    }
  };

  useEffect(() => {
    fetchProfileImage();
    fetchCoverImage();
  }, []);

  return (
    <StyledArtistCard coverImage={coverImageFile}>
      <div className="cover"></div>
      <div className="container">
        <Spring className="main d-flex flex-column bg-primary border-10">
          <Grid container my={3}>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Avatar
                size="lg"
                src={profileImageFile}
                alt={`${name || username}`}
                isVerified={false}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={8}>
              <Grid container display={"flex"} justifyContent={"center"} mt={3}>
                <Grid item sm={4} xs={12} md={4}>
                  <Grid container>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{ opacity: 0.7 }}
                    >
                      <p className="text-bold text-light">@{username}</p>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <h3 className="main_info-name">{name || username}</h3>
                      <Verified
                        fontSize="medium"
                        sx={{ color: "#42A5F5", marginLeft: "5px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  pt={2}
                  item
                  sm={8}
                  xs={12}
                  md={8}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <Button
                    onClick={() => navigateToPlatform("apple")}
                    style={{ color: "#fa57c1" }}
                  >
                    <FaApple size={26} />
                  </Button>
                  <Button
                    onClick={() => navigateToPlatform("youtube")}
                    style={{ color: "red" }}
                  >
                    <FaYoutube size={26} />
                  </Button>
                  <Button
                    onClick={() => navigateToPlatform("spotify")}
                    style={{ color: "limegreen" }}
                  >
                    <FaSpotify size={26} />
                  </Button>
                  <Button
                    onClick={() => navigateToPlatform("soundcloud")}
                    style={{ color: "orange" }}
                  >
                    <FaSoundcloud size={26} />
                  </Button>
                  <Button
                    onClick={() => navigateToPlatform("deezer")}
                    style={{ color: "blue" }}
                  >
                    <FaDeezer size={26} />
                  </Button>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  pt={3}
                  px={5}
                  sx={{ opacity: 0.7 }}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  textAlign={"left"}
                  ref={ref}
                >
                  <CollapsedText
                    width={width}
                    text={bio}
                    lines={3}
                    withButton
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Spring>
      </div>
    </StyledArtistCard>
  );
};

export default ArtistCard;
