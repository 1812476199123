export const formattedAWSDate = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const timeDifference = (firstDate, secondDate) => {
  // Parse the formatted date strings back into Date objects
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  // Calculate the time difference in milliseconds
  const timeDifference = date2 >= date1 ? date2 - date1 : date1 - date2;

  // Convert the time difference into days
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

export const unformattedAWSDate = (date) => {
  if (!date) {
    return null;
  }
  // Convert AWSDate to JavaScript Date
  const awsDateParts = date.split("-");
  const awsYear = parseInt(awsDateParts[0]);
  const awsMonth = parseInt(awsDateParts[1]) - 1; // Months are 0-indexed
  const awsDay = parseInt(awsDateParts[2]);
  return new Date(awsYear, awsMonth, awsDay);
};

export const toCustomDateFormat = (inputDate) => {
  if (!inputDate) {
    return "";
  }
  // Split the input date into year, month, and day parts
  const [year, month, day] = inputDate.split("-");

  // Create a Date object from the input parts
  const date = new Date(year, month - 1, day);

  // Define the month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day, month, and year
  const formattedDay =
    day +
    (day.endsWith("1") && day !== "11"
      ? "st"
      : day.endsWith("2") && day !== "12"
      ? "nd"
      : day.endsWith("3") && day !== "13"
      ? "rd"
      : "th");
  const formattedMonth = monthNames[date.getMonth()];
  const formattedYear = year;

  // Format the date as "24th Nov, 2023"
  const formattedDate = `${formattedDay} ${formattedMonth}, ${formattedYear}`;

  return formattedDate;
};
