import axios from "axios";

const serverUrl = `${process.env.REACT_APP_HEDERA_API_URL}`; // Replace with your server URL

// Singleton Axios instance
let axiosInstance = null;

// Function to create or get the Axios instance
const getAxiosInstance = async () => {
  try {
    if (!axiosInstance) {
      axiosInstance = axios.create({
        baseURL: serverUrl,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      });
    }
    return axiosInstance;
  } catch (error) {
    throw error;
  }
};

// Function to send a POST request with the provided data
const getRequest = async (endpoint) => {
  try {
    const axiosInstance = await getAxiosInstance(); // Get the Axios instance
    const response = await axiosInstance.get(endpoint); // Use the instance for the request
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Gets a wallet address as a parameter and returns  the status of the latest transaction
export const getLatestTransactionStatus = async (idOrAliasOrEvmAddress) => {
  try {
    const endpoint = `/accounts/${idOrAliasOrEvmAddress}/`;
    const result = await getRequest(endpoint);
    const transactions = result.transactions;
    if (transactions.length > 0 && transactions[0]?.result) {
      return transactions[0].result;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
};
export const isNftAssociatedToAccount = async (idOrAliasOrEvmAddress) => {
  try {
    const nftTokenId = process.env.REACT_APP_NFT_TOKEN_ID;
    const endpoint = `/accounts/${idOrAliasOrEvmAddress}/tokens?token.id=${nftTokenId}`;
    const result = await getRequest(endpoint);
    const tokens = result.tokens;
    if (tokens?.length > 0 && tokens[0]?.balance >= 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const isUsdcAssociatedToAccount = async (idOrAliasOrEvmAddress) => {
  try {
    const usdcTokenId = process.env.REACT_APP_USDC_TOKEN_ID;
    const endpoint = `/accounts/${idOrAliasOrEvmAddress}/tokens?token.id=${usdcTokenId}`;
    const result = await getRequest(endpoint);
    const tokens = result.tokens;
    if (tokens?.length > 0 && tokens[0]?.balance >= 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

// Return the current account usdc balance in (6 decimal places) => 1 USDC = 1,000,000
export const getCurrentUsdcBalance = async (idOrAliasOrEvmAddress) => {
  try {
    const usdcTokenId = process.env.REACT_APP_USDC_TOKEN_ID;
    const endpoint = `/accounts/${idOrAliasOrEvmAddress}/tokens?token.id=${usdcTokenId}`;
    const result = await getRequest(endpoint);
    const tokens = result.tokens;
    if (tokens?.length > 0 && tokens[0].balance > 0) {
      return tokens[0].balance;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

// Return the current account hbar balance in (8 decimal places) => 1 hbar = 100,000,000
export const getCurrentHbarBalance = async (idOrAliasOrEvmAddress) => {
  try {
    const endpoint = `/balances/?account.id=${idOrAliasOrEvmAddress}`;
    const result = await getRequest(endpoint);
    const balance = result?.balances[0]?.balance || 0;
    if (balance > 0) {
      return balance;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

// Return the current account nfts
export const getCurrentNfts = async (idOrAliasOrEvmAddress) => {
  try {
    const nftTokenId = process.env.REACT_APP_NFT_TOKEN_ID;
    const endpoint = `/accounts/${idOrAliasOrEvmAddress}/nfts?token.id=${nftTokenId}`;
    const result = await getRequest(endpoint);
    const { nfts = [] } = result;
    return nfts;
  } catch (error) {
    return [];
  }
};
