import { Button, Grid } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { getImageFromIndexedDB } from "@helpers/indexedDB/imageDB";
import { useEffect, useState } from "react";
import LazyImage from "@components/LazyImage";
import styles from "./style.module.scss";

const TrackHeader = ({ musicRelease, onTrackClick, isOpen = false }) => {
  const handleClick = () => {
    onTrackClick(musicRelease.serial);
  };

  const [artImage, setArtImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const result = await getImageFromIndexedDB("artImageLink");
        if (result && result.data) {
          setArtImage(result.data);
        }
      } catch (error) {
        console.log("Failed to open IndexedDB:", error);
      }
    };

    fetchImage();
  }, []);

  return (
    <Grid
      container
      marginTop={"20px"}
      borderRadius={5}
      bgcolor={"black"}
      marginLeft={"auto"}
      marginRight={"auto"}
      alignItems={"center"}
      justifyContent="space-between" // Adjust the alignment as needed
      width={"80%"}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      {artImage && (
        <Grid item xs={2} sm={2} md={1}>
          <LazyImage
            className={styles.cover}
            src={artImage}
            alt="Cover Image"
          />
        </Grid>
      )}
      <Grid item xs={2} sm={2} md={1} textAlign={"left"}>
        {musicRelease.serial === 1 ? (
          <p>Single</p>
        ) : (
          <p>No. {musicRelease.serial}</p>
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={8}>
        <h6>{musicRelease.title}</h6>
        {musicRelease.featureArtist && (
          <p>feat. {musicRelease.featureArtist}</p>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        alignSelf={"center"}
        textAlign={"right"}
        padding={1}
      >
        {isOpen ? (
          <ExpandLess sx={{ fontSize: "2rem" }} />
        ) : (
          <ExpandMore sx={{ fontSize: "2rem" }} />
        )}
      </Grid>
    </Grid>
  );
};

export default TrackHeader;
