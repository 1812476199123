// styling
import Lottie from "lottie-react";
import styles from "./style.module.scss";

// utils
import PropTypes from "prop-types";
import greenActive from "../../assets/lottie/green-active.json"; // Import your Lottie animation JSON file

const SectionHeader = ({ title, live = false, children }) => {
  return (
    <div className={styles.header}>
      <h3
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {title}
        {live && (
          <Lottie
            animationData={greenActive} // Pass your animation JSON data here
            loop={true}
            style={{ width: "60px", height: "60px" }} // Set the width and height of the animation
          />
        )}
      </h3>
      {children}
    </div>
  );
};

SectionHeader.propTypes = {
  live: PropTypes.bool,
  title: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export default SectionHeader;
