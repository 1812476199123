// components
import { Helmet } from "react-helmet";

const Title = ({ title }) => {
  return (
    <Helmet>
      <title style={{ fontFamily: "sans-serif", fontWeight: "bold" }}>
        {title} | Probal
      </title>
    </Helmet>
  );
};

export default Title;
