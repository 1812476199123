// styles
import styles from "./style.module.scss";

// components
import { NavLink } from "react-router-dom";
import LazyImage from "@components/LazyImage";

// utils
import { memo } from "react";

// assets
import logo from "@assets/logo-white.png";

const UserTag = ({ avatar = logo, name = "Probal Team", ...props }) => {
  return (
    <div
      className={`${styles.container} d-flex align-items-center g-10 bg-secondary`}
      {...props}
    >
      <LazyImage className={styles.avatar} src={avatar} alt={name} />
      {name !== "Probal Team" ? (
        <NavLink to={"/user/" + name.replace(/\s/g, "-")}>{`@${name}`}</NavLink>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(UserTag);
