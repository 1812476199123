import React, { useState } from "react";
import styles from "./style.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import ItemsGridItemSkeleton from "@components/ItemsGrid/ItemsGridItem/ItemsGridItemSkeleton";

const ArtistMetricsTable = ({ artistMetricsTableData }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("quarter");

  if (!artistMetricsTableData) {
    return <ItemsGridItemSkeleton />;
  }

  const handleButtonClick = (period) => {
    setSelectedPeriod(period);
  };

  const addCommasToNumber = (number) => {
    if (typeof number !== "number") {
      return "-";
    }

    return number.toLocaleString();
  };

  const getBreakdownTitle = (breakdown) => {
    switch (breakdown) {
      case "currSpotifyFollowers":
        return "Total Spotify Followers";
      case "currSpotifyMonthlyListeners":
        return "Total Spotify Monthly Listeners";
      case "currYoutubeViews":
        return "Total Youtube Views";
      case "currYoutubeSubs":
        return "Total Youtube Subscribers";
      case "currSoundcloudFollowers":
        return "Total SoundCloud Followers";
      case "currDeezerFans":
        return "Total Deezer Fans";
    }
  };

  const getColumns = () => {
    if (!artistMetricsTableData) {
      return [];
    }
    const periods =
      selectedPeriod === "quarter"
        ? Object.keys(artistMetricsTableData?.quarters).reverse()
        : Object.keys(artistMetricsTableData?.years).reverse();
    return [
      "Breakdown",
      ...periods.map((period) =>
        selectedPeriod === "quarter" ? `${period} 2023` : `${period}`
      ),
    ];
  };

  const getMetricData = () => {
    const periods =
      selectedPeriod === "quarter"
        ? Object.keys(artistMetricsTableData.quarters).reverse()
        : Object.keys(artistMetricsTableData.years).reverse();

    const params =
      selectedPeriod === "quarter"
        ? artistMetricsTableData.quarters[periods[0]]
        : artistMetricsTableData.years[periods[0]];

    return Object.keys(params).map((metric) => ({
      breakdown: metric,
      values: periods.map((period) => {
        const periodData =
          selectedPeriod === "quarter"
            ? artistMetricsTableData.quarters[period]
            : artistMetricsTableData.years[period];
        return periodData[metric];
      }),
    }));
  };

  return (
    <div>
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <ButtonGroup>
          <Button
            variant="text"
            onClick={() => handleButtonClick("quarter")}
            style={{
              color: selectedPeriod === "quarter" ? "#ababab" : "",
              textTransform: "none",
              fontFamily: "var(--heading-font)",
            }}
          >
            Quarterly
          </Button>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: "#ababab",
              fontFamily: "var(--heading-font)",
            }}
          >
            |
          </span>
          <Button
            variant="text"
            onClick={() => handleButtonClick("year")}
            style={{
              color: selectedPeriod === "year" ? "#ababab" : "",
              textTransform: "none",
              fontFamily: "var(--heading-font)",
            }}
          >
            Annually
          </Button>
        </ButtonGroup>
      </div>

      <TableContainer
        component={Paper}
        className={styles.table}
        sx={{ background: "rgba(0, 0, 0, 0.2)", maxHeight: 600 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {getColumns().map((column, index) => (
                <TableCell
                  key={column}
                  style={{
                    background: index % 2 === 0 ? "#e3e3e30a" : "",
                    color: "white",
                    fontFamily: "var(--heading-font)",
                    opacity: 0.6,
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getMetricData().map(({ breakdown, values }, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    background: "#000",
                    boxShadow: "inset -3px 0 8px -6px rgba(255, 255, 255, 0.6)",
                    fontFamily: "var(--heading-font)",
                  }}
                >{`${getBreakdownTitle(breakdown)}`}</TableCell>
                {values.map((value, idx) => (
                  <TableCell
                    key={idx}
                    style={{
                      background: idx % 2 === 1 ? "#e3e3e30a" : "",
                      color: "white",
                      fontSize: "small",
                      fontFamily: "var(--heading-font)",
                      opacity: 0.85,
                    }}
                  >
                    {addCommasToNumber(value)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ArtistMetricsTable;
