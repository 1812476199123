import { Auth } from "aws-amplify";
import {
  createUserMutation,
  findUserByPublicAddress,
} from "@helpers/appsync/api";
import { toast } from "react-toastify";
import { RaiseFundsApprovalStatus, UserRole } from "models";
import { SHA256 } from "crypto-js";

/**
 * The `loginUser` function is an asynchronous function that takes a public address, dispatch function,
 * signature, and setLoading function as parameters, and attempts to log in a user by retrieving their
 * username and password using the `getUserKeys` function and then signing them in using the
 * `signUserWithKeys` function.
 * @param publicAddress - The public address is the unique identifier for a user's wallet. It is a
 * string that represents the user's Ethereum address.
 * @param dispatch - The `dispatch` parameter is a function that is used to dispatch actions to the
 * Redux store. It is typically provided by the `react-redux` library and is used to update the state
 * of the application.
 * @param signature - The `signature` parameter is a cryptographic signature generated by the user's
 * wallet. It is used to verify the authenticity of the user's public address.
 * @param setLoading - The `setLoading` parameter is a function that is used to update the loading
 * state of the component. It is typically used to show a loading spinner or disable certain UI
 * elements while an asynchronous operation is in progress.
 */
export const loginUser = async (
  publicAddress,
  dispatch,
  signature,
  setLoading,
  email = ""
) => {
  try {
    const { username, password } = await getUserKeys(publicAddress, signature);
    await signUserWithKeys(
      username,
      password,
      publicAddress,
      dispatch,
      setLoading,
      email
    );
  } catch (error) {
    toast.error("Failed to connect the wallet!");
  }
};

/**
 * The function `getUserKeys` takes a public address and a signature, hashes them using `hashString`,
 * and returns an object with the hashed username and password.
 * @param publicAddress - The `publicAddress` parameter is a string representing the public address of
 * a user. It is typically used in blockchain applications to identify a user's account.
 * @param signature - The `signature` parameter is a string that represents a cryptographic signature.
 * It is typically generated using a private key and is used to verify the authenticity and integrity
 * of data. In this context, it is being used as input to the `hashString` function to generate a
 * hashed password.
 * @returns An object containing the username and password.
 */
const getUserKeys = async (publicAddress, signature) => {
  const username = await hashString(publicAddress);
  const password = await hashString(signature);
  return { username, password };
};

/**
 * The function `signUserWithKeys` signs in a user with their username, password, and public address,
 * and then retrieves the user's profile and updates the state accordingly.
 * @param username - The username of the user trying to sign in or sign up.
 * @param password - The password parameter is the user's password that they use to sign in to their
 * account.
 * @param publicAddress - The public address is a unique identifier associated with a user's
 * cryptocurrency wallet. It is used to verify ownership of the wallet and to perform transactions on
 * the blockchain.
 * @param dispatch - The `dispatch` parameter is a function that is used to dispatch actions to update
 * the state of the application. It is typically provided by a state management library such as Redux
 * or React Context.
 * @param setLoading - A function that sets the loading state of the application. It takes an object
 * with two properties: "isShown" (a boolean indicating whether the loading indicator should be shown)
 * and "message" (a string representing the loading message).
 */
const signUserWithKeys = async (
  username,
  password,
  publicAddress,
  dispatch,
  setLoading,
  email = ""
) => {
  try {
    setLoading({ isShown: true, message: "Signing User..." });
    await signInUser(username, password);
  } catch (e) {
    if (e instanceof UserNotFoundException) {
      await signUpUser(username, password, publicAddress, email);
      await signInUser(username, password);
    }
  }

  try {
    const profile = await findUserByPublicAddress(publicAddress);
    if (profile) {
      dispatch({ type: "SET_PROFILE", payload: profile });
      setLoading({ isShown: false, message: "" });
      toast.success("Wallet Connected!");
    } else {
      setLoading({ isShown: false, message: "" });
      toast.info(
        `Failed to connect to the account! Please Refresh & Try Again`
      );
    }
  } catch (error) {
    setLoading({ isShown: false, message: "" });
  }
};

const hashString = async (str) => {
  /**
   * The function `hashString` takes a string as input and returns its SHA256 hash value as a string.
   * @param str - The `str` parameter is a string that will be hashed using the SHA256 algorithm.
   * @returns The SHA256 hash of the input string as a string.
   */
  return SHA256(str).toString();
};

/* The UserNotFoundException class is a custom error class in JavaScript that represents an exception
when a user is not found. */
class UserNotFoundException extends Error {
  constructor(message) {
    super(message);
    this.name = "UserNotFoundException";
  }
}

/**
 * The function `signInUser` signs in a user with a given username and password using the
 * `Auth.signIn` method, and throws a `UserNotFoundException` if the user is not found.
 * @param username - The username parameter is a string that represents the username of the user
 * trying to sign in.
 * @param password - The `password` parameter is the password entered by the user for signing in.
 * @returns The `signInUser` function is returning the `user` object if the sign-in is successful.
 */

const signInUser = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    if (error.name === "UserNotFoundException") {
      throw new UserNotFoundException("Failed to sign in");
    }
  }
};

/**
 * The function `signUpUser` signs up a user with a given username, password, and public address, and
 * creates a new user with additional information.
 * @param username - The username parameter is a string that represents the username of the user
 * signing up. It is used to identify the user and should be unique.
 * @param password - The `password` parameter is the password that the user wants to set for their
 * account during the sign-up process.
 * @param publicAddress - The `publicAddress` parameter is a string that represents the public address
 * of the user. It is typically used in blockchain applications to identify and interact with a user's
 * account on the blockchain network.
 */
const signUpUser = async (username, password, publicAddress, email = "") => {
  try {
    const name = "";
    await Auth.signUp({
      username,
      password,
      attributes: {
        name,
      },
    });
    const newUser = {
      publicAddress,
      role: UserRole.MEMBER,
      raiseFundsApprovalStatus: RaiseFundsApprovalStatus.NOT_PERMITTED,
      email,
    };
    await createUserMutation(newUser);
  } catch (error) {
    throw new Error("Failed to sign up");
  }
};
/**
 * The `logoutUser` function signs out the user and updates the profile state to null.
 * @param dispatch - The `dispatch` parameter is a function that is used to send actions to the Redux
 * store. It is typically provided by the Redux library and is used to update the state of the
 * application.
 */

export const logoutUser = async (dispatch) => {
  await Auth.signOut();
  dispatch({ type: "SET_PROFILE", payload: null });
};
