// styled components
import { StyledUserItems, CollectionsGrid } from "./style";

// components
import ItemsGrid from "@components/ItemsGrid";
import Pagination from "@ui/Pagination";
import CollectionItem from "@components/CollectionItem";

// hooks
import usePagination from "@hooks/usePagination";

const SingleItems = ({ content }) => {
  const pagination = usePagination(content, 12);

  return (
    <div className="tab-content" ref={pagination.containerRef}>
      <ItemsGrid items={pagination.currentItems()} />
      {pagination.maxPage > 1 && <Pagination pagination={pagination} />}
    </div>
  );
};

const Collections = ({ content }) => {
  const pagination = usePagination(content, 6);

  return (
    <div className="tab-content" ref={pagination.containerRef}>
      <CollectionsGrid>
        {pagination.currentItems().map((item, index) => (
          <CollectionItem item={item} index={index} key={index} />
        ))}
      </CollectionsGrid>
      {pagination.maxPage > 1 && <Pagination pagination={pagination} />}
    </div>
  );
};

const UserItems = (user) => {
  // const likedItems = user.creations.filter((item) => item.isLiked);

  // const tabs = [
  //   {
  //     label: `Creations (${user.creations.length})`,
  //     key: "item-1",
  //     children: <SingleItems content={user.creations} />,
  //   },
  //   {
  //     label: `Collections (${user.collections.length})`,
  //     key: "item-2",
  //     children: <Collections content={user.collections} />,
  //   },
  //   {
  //     label: `Favorites (${likedItems.length})`,
  //     key: "item-3",
  //     children: <SingleItems content={likedItems} />,
  //   },
  // ];

  return (
    <StyledUserItems>
      <div className="container">{/* <StyledTabs tabs={tabs} /> */}</div>
    </StyledUserItems>
  );
};

export default UserItems;
