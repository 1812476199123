import axios from "axios";
import { Auth } from "aws-amplify"; // Import the Auth module from AWS Amplify

const serverUrl = `${process.env.REACT_APP_API_URL}/api/contract`; // Replace with your server URL

// Singleton Axios instance
let axiosInstance = null;

// Function to get the AWS Amplify authentication token
const getAuthToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  } catch (error) {
    throw error;
  }
};

// Function to create or get the Axios instance
const getAxiosInstance = async () => {
  try {
    if (!axiosInstance) {
      const authToken = await getAuthToken();

      axiosInstance = axios.create({
        baseURL: serverUrl,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      });
    }
    return axiosInstance;
  } catch (error) {
    throw error;
  }
};

// Function to send a POST request with the provided data
const sendPostRequest = async (endpoint, data) => {
  try {
    const axiosInstance = await getAxiosInstance(); // Get the Axios instance
    const response = await axiosInstance.post(endpoint, data); // Use the instance for the request
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to add a project
export const addProject = async (
  id,
  timeWindow,
  aimingAmount,
  ownershipAmount,
  creatorId
) => {
  try {
    const endpoint = "/add-project-main";
    const data = { id, timeWindow, aimingAmount, ownershipAmount, creatorId };
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};

// Function to add revenue
export const addRevenue = async (projectId, amount) => {
  try {
    const endpoint = "/add-revenue-main";
    const data = { projectId, amount };
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};

export const getStatus = async () => {
  try {
    const endpoint = "/status";
    const data = {};
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};

// Function to mint NFT
export const mintNFTs = async (projectId, investorAddress, nfts) => {
  try {
    const endpoint = "/mint";
    const data = {
      projectId,
      investorAddress,
      nfts,
    };
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};

// Function to send investment escrow
export const sendFromInvestmentEscrow = async (projectId) => {
  try {
    const endpoint = "/send-investment-escrow";
    const data = { projectId };
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};

// Function to send distribute From Distribution Escrow
export const distributeFromDistributionEscrow = async (projectId) => {
  try {
    const endpoint = "/distribute-distribution-escrow";
    const data = { projectId };
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};

// Function to activate new user usdc by sending small amount of hbar
export const activateNewUserUsdc = async (investorAddress) => {
  try {
    const endpoint = "/activate-new-user-usdc";
    const data = { investorAddress };
    return await sendPostRequest(endpoint, data);
  } catch (error) {
    throw error;
  }
};
