/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNftMetadata = /* GraphQL */ `
  subscription OnCreateNftMetadata(
    $filter: ModelSubscriptionNftMetadataFilterInput
  ) {
    onCreateNftMetadata(filter: $filter) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const onUpdateNftMetadata = /* GraphQL */ `
  subscription OnUpdateNftMetadata(
    $filter: ModelSubscriptionNftMetadataFilterInput
  ) {
    onUpdateNftMetadata(filter: $filter) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const onDeleteNftMetadata = /* GraphQL */ `
  subscription OnDeleteNftMetadata(
    $filter: ModelSubscriptionNftMetadataFilterInput
  ) {
    onDeleteNftMetadata(filter: $filter) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const onCreateArtist = /* GraphQL */ `
  subscription OnCreateArtist($filter: ModelSubscriptionArtistFilterInput) {
    onCreateArtist(filter: $filter) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const onUpdateArtist = /* GraphQL */ `
  subscription OnUpdateArtist($filter: ModelSubscriptionArtistFilterInput) {
    onUpdateArtist(filter: $filter) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const onDeleteArtist = /* GraphQL */ `
  subscription OnDeleteArtist($filter: ModelSubscriptionArtistFilterInput) {
    onDeleteArtist(filter: $filter) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const onCreateHolder = /* GraphQL */ `
  subscription OnCreateHolder($filter: ModelSubscriptionHolderFilterInput) {
    onCreateHolder(filter: $filter) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const onUpdateHolder = /* GraphQL */ `
  subscription OnUpdateHolder($filter: ModelSubscriptionHolderFilterInput) {
    onUpdateHolder(filter: $filter) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const onDeleteHolder = /* GraphQL */ `
  subscription OnDeleteHolder($filter: ModelSubscriptionHolderFilterInput) {
    onDeleteHolder(filter: $filter) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($filter: ModelSubscriptionProjectFilterInput) {
    onCreateProject(filter: $filter) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($filter: ModelSubscriptionProjectFilterInput) {
    onUpdateProject(filter: $filter) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($filter: ModelSubscriptionProjectFilterInput) {
    onDeleteProject(filter: $filter) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const onCreateArt = /* GraphQL */ `
  subscription OnCreateArt($filter: ModelSubscriptionArtFilterInput) {
    onCreateArt(filter: $filter) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const onUpdateArt = /* GraphQL */ `
  subscription OnUpdateArt($filter: ModelSubscriptionArtFilterInput) {
    onUpdateArt(filter: $filter) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const onDeleteArt = /* GraphQL */ `
  subscription OnDeleteArt($filter: ModelSubscriptionArtFilterInput) {
    onDeleteArt(filter: $filter) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const onCreateMusicRelease = /* GraphQL */ `
  subscription OnCreateMusicRelease(
    $filter: ModelSubscriptionMusicReleaseFilterInput
  ) {
    onCreateMusicRelease(filter: $filter) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const onUpdateMusicRelease = /* GraphQL */ `
  subscription OnUpdateMusicRelease(
    $filter: ModelSubscriptionMusicReleaseFilterInput
  ) {
    onUpdateMusicRelease(filter: $filter) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const onDeleteMusicRelease = /* GraphQL */ `
  subscription OnDeleteMusicRelease(
    $filter: ModelSubscriptionMusicReleaseFilterInput
  ) {
    onDeleteMusicRelease(filter: $filter) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const onCreateMusicRoyalty = /* GraphQL */ `
  subscription OnCreateMusicRoyalty(
    $filter: ModelSubscriptionMusicRoyaltyFilterInput
  ) {
    onCreateMusicRoyalty(filter: $filter) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const onUpdateMusicRoyalty = /* GraphQL */ `
  subscription OnUpdateMusicRoyalty(
    $filter: ModelSubscriptionMusicRoyaltyFilterInput
  ) {
    onUpdateMusicRoyalty(filter: $filter) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const onDeleteMusicRoyalty = /* GraphQL */ `
  subscription OnDeleteMusicRoyalty(
    $filter: ModelSubscriptionMusicRoyaltyFilterInput
  ) {
    onDeleteMusicRoyalty(filter: $filter) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const onCreateHolding = /* GraphQL */ `
  subscription OnCreateHolding($filter: ModelSubscriptionHoldingFilterInput) {
    onCreateHolding(filter: $filter) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const onUpdateHolding = /* GraphQL */ `
  subscription OnUpdateHolding($filter: ModelSubscriptionHoldingFilterInput) {
    onUpdateHolding(filter: $filter) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const onDeleteHolding = /* GraphQL */ `
  subscription OnDeleteHolding($filter: ModelSubscriptionHoldingFilterInput) {
    onDeleteHolding(filter: $filter) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const onCreateDistributionTransaction = /* GraphQL */ `
  subscription OnCreateDistributionTransaction(
    $filter: ModelSubscriptionDistributionTransactionFilterInput
  ) {
    onCreateDistributionTransaction(filter: $filter) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
export const onUpdateDistributionTransaction = /* GraphQL */ `
  subscription OnUpdateDistributionTransaction(
    $filter: ModelSubscriptionDistributionTransactionFilterInput
  ) {
    onUpdateDistributionTransaction(filter: $filter) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
export const onDeleteDistributionTransaction = /* GraphQL */ `
  subscription OnDeleteDistributionTransaction(
    $filter: ModelSubscriptionDistributionTransactionFilterInput
  ) {
    onDeleteDistributionTransaction(filter: $filter) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
