const initialState = [];

const artistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ARTISTS":
      return action.payload;
    case "UPDATE_ARTIST":
      // Find the project to update in the array
      const updatedArtist = action.payload;
      const updatedArtists = state.map((artist) =>
        artist.id === updatedArtist.id ? updatedArtist : artist
      );
      return updatedArtists;

    default:
      return state;
  }
};

export default artistsReducer;
