// components
import LazyImage from "@components/LazyImage";

// utils
import classNames from "classnames";
import PropTypes from "prop-types";
import { memo } from "react";

// assets
import { Verified } from "@mui/icons-material";

const Avatar = ({ src, alt, size, isVerified, className }) => {
  return (
    <div className={classNames("avatar-wrapper", size, className)}>
      <LazyImage className="avatar" src={src} alt={alt} />
      {isVerified && (
        <div className="verified">
          <Verified fontSize="small" sx={{ color: "#42A5F5" }} />
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg"]),
  isVerified: PropTypes.bool,
};

export default memo(Avatar);
