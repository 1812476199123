export const getExploreProjectsQuery = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectFundraiserId
        createdAt
        description
        endRaisingDate
        sharedPercentage
        startRaisingDate
        updatedAt
        amountRaised
        amountRaising
        approvalStatus
        art {
          artArtistId
          compressedCoverImage
          originalCoverImage
          createdAt
          id
          title
          updatedAt
          artist {
            user {
              profileImage
              name
              publicAddress
              username
            }
          }
        }
        fundraiser {
          profileImage
          name
          publicAddress
          username
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listAllArtsQuery = /* GraphQL */ `
  query ListArts(
    $filter: ModelArtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artist {
          id
          user {
            username
            name
            profileImage
            publicAddress
          }
        }
        musicReleases {
          items {
            id
          }
        }
        artArtistId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getHolderHoldingsQuery = /* GraphQL */ `
  query ListHoldings(
    $filter: ModelHoldingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHoldings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountInvested
        updatedAt
        transactionId
        holdingProjectId
        holdingHolderId
        createdAt
        project {
          id
          amountRaised
          amountRaising
          approvalStatus
          createdAt
          endRaisingDate
          description
          sharedPercentage
          startRaisingDate
          updatedAt
          projectFundraiserId
          projectArtId
          art {
            artArtistId
            compressedCoverImage
            createdAt
            id
            originalCoverImage
            title
            updatedAt
            artist {
              user {
                name
                profileImage
                username
              }
            }
          }
        }
      }
    }
  }
`;

export const getOwnUserProjectsQuery = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        projectFundraiserId
        createdAt
        description
        endRaisingDate
        sharedPercentage
        startRaisingDate
        updatedAt
        amountRaised
        amountRaising
        approvalStatus
        art {
          artArtistId
          compressedCoverImage
          originalCoverImage
          createdAt
          id
          title
          updatedAt
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getArtIdByProjectQuery = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      art {
        id
        __typename
      }
      __typename
    }
  }
`;

export const getOwnUserArtsQuery = /* GraphQL */ `
  query ListArts(
    $filter: ModelArtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        title
        artArtistId
        compressedCoverImage
        originalCoverImage
        artist {
          id
          artistUserId
        }
        musicReleases {
          items {
            id
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getDetailedProjectByProjectArtIdQuery = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectFundraiserId
        projectArtId
        createdAt
        updatedAt
        description
        amountRaised
        amountRaising
        approvalStatus
        startRaisingDate
        endRaisingDate
        sharedPercentage
        art {
          id
          artist {
            user {
              name
              profileImage
              username
              publicAddress
            }
          }
          originalCoverImage
          title
          updatedAt
          createdAt
          compressedCoverImage
          musicReleases {
            items {
              id
              title
            }
          }
        }
        holdings {
          items {
            amountInvested
            transactionId
            createdAt
            holder {
              user {
                username
                profileImage
              }
            }
          }
          nextToken
          __typename
        }
      }
      nextToken
    }
  }
`;

export const getDetailedProjectQuery = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      projectFundraiserId
      createdAt
      updatedAt
      description
      amountRaised
      amountRaising
      approvalStatus
      startRaisingDate
      endRaisingDate
      sharedPercentage
      art {
        id
        originalCoverImage
        title
        updatedAt
        createdAt
        compressedCoverImage
        musicReleases {
          items {
            id
            title
          }
        }
      }
      holdings {
        items {
          amountInvested
          transactionId
          createdAt
          holder {
            user {
              username
              profileImage
            }
          }
        }
        nextToken
        __typename
      }
    }
  }
`;

export const usersByPublicAddressQuery = /* GraphQL */ `
  query UsersByPublicAddress(
    $publicAddress: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByPublicAddress(
      publicAddress: $publicAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicAddress
        username
        name
        bio
        email
        profileImage
        referrer
        coverImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getSummerizedProjectQuery = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      createdAt
      updatedAt
      description
      amountRaised
      amountRaising
      approvalStatus
      startRaisingDate
      endRaisingDate
      sharedPercentage
      projectFundraiserId
      art {
        originalCoverImage
        title
        updatedAt
        createdAt
        compressedCoverImage
      }
    }
  }
`;

export const getTopArtistsQuery = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicAddress
        username
        name
        bio
        profileImage
        coverImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getUsersAdminQuery = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicAddress
        username
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getProjectsByAdminQuery = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountRaised
        amountRaising
        sharedPercentage
        approvalStatus
        createdAt
        updatedAt
        description
        startRaisingDate
        endRaisingDate
        art {
          id
          title
          compressedCoverImage
          originalCoverImage
          createdAt
          updatedAt
          musicReleases {
            items {
              id
              category
              createdAt
              featureArtist
              file
              lyrics
              productionHolder
              productionYear
              copyrightHolder
              copyrightYear
              releaseDate
              songWriter
              releaseStatus
              title
              updatedAt
            }
          }
          artist {
            user {
              id
              profileImage
              publicAddress
              username
              email
              name
            }
          }
        }
        fundraiser {
          id
          name
          profileImage
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listHoldingsByProjectQuery = /* GraphQL */ `
  query ListHoldings(
    $filter: ModelHoldingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHoldings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holder {
          user {
            username
            profileImage
            name
          }
        }
        holdingProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const previewMusicRelease = /* GraphQL */ `
  query ListMusicReleases(
    $filter: ModelMusicReleaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMusicReleases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        previewFile
        musicReleaseArtId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
