import React from "react";
import { Grid, Skeleton, Button } from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import GradientBtn from "@ui/GradientBtn";

// Create a styled Backdrop component with blur effect
const StyledBackdrop = styled("div")(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
  position: "absolute",
  top: -5,
  left: -5,
  right: -5,
  bottom: -5,
  backdropFilter: "blur(8px)", // Adjust the blur strength as needed
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ArtistHeaderDataSkeleton = () => {
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();

  const isWalletConnected = () => {
    return profile?.id.length;
  };

  const handleConnectWallet = () => {
    navigate("/login");
  };

  return (
    <Grid container position="relative">
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Skeleton
          animation={false}
          sx={{
            backgroundColor: "gray",
            margin: "20px",
            flex: 1,
            borderRadius: "20px",
          }}
          height={80}
          variant="rectangular"
        />
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Skeleton
          animation={false}
          sx={{
            backgroundColor: "gray",
            margin: "20px",
            flex: 1,
            borderRadius: "20px",
          }}
          height={80}
          variant="rectangular"
        />
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Skeleton
          animation={false}
          sx={{
            backgroundColor: "gray",
            margin: "20px",
            flex: 1,
            borderRadius: "20px",
          }}
          height={80}
          variant="rectangular"
        />
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Skeleton
          animation={false}
          sx={{
            backgroundColor: "gray",
            margin: "20px",
            flex: 1,
            borderRadius: "20px",
          }}
          height={80}
          variant="rectangular"
        />
      </Grid>

      {!isWalletConnected() && (
        <StyledBackdrop>
          <GradientBtn onClick={handleConnectWallet}>Connect</GradientBtn>
        </StyledBackdrop>
      )}
    </Grid>
  );
};

export default ArtistHeaderDataSkeleton;
