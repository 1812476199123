import { combineReducers } from "redux";
import projectsReducer from "./explore/projectsReducer";
import profileReducer from "./profileReducer";
import raiseFundsFormReducer from "./forms/raiseFundsFormReducer";
import artFormReducer from "./forms/artFormReducer";
import musicReleasesFormReducer from "./forms/musicReleasesFormReducer";

import clickedProjectReducer from "./explore/clickedProjectReducer";
import walletTypeReducer from "./walletTypeReducer";
import releasesReducer from "./dashboard/releasesReducer";
import holdingsReducer from "./dashboard/holdingsReducer";
import fundraisingsReducer from "./dashboard/fundraisingsReducer";
import artistsReducer from "./explore/artistsReducer";

const rootReducer = combineReducers({
  profile: profileReducer,
  walletType: walletTypeReducer,
  projects: projectsReducer,
  raiseFundsForm: raiseFundsFormReducer,
  artForm: artFormReducer,
  musicReleasesForm: musicReleasesFormReducer,
  clickedProject: clickedProjectReducer,
  releases: releasesReducer,
  holdings: holdingsReducer,
  fundraisings: fundraisingsReducer,
  artists: artistsReducer,
});

export default rootReducer;
