import { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PathContext = createContext("");

export const PathProviderAPI = ({ children }) => {
  const location = useLocation();
  const [currPath, setCurrPath] = useState("");
  const [prevPath, setPrevPath] = useState("");

  useEffect(() => {
    setPrevPath(currPath);
    setCurrPath(location.pathname);
  }, [location.pathname]);

  return (
    <PathContext.Provider value={{ currPath, prevPath }}>
      {children}
    </PathContext.Provider>
  );
};

export const usePath = () => useContext(PathContext);
