const initialState = {
  musicType: null,
  releaseDate: null,
  title: "",
  originalCoverImage: "",
  compressedCoverImage: "",
  copyrightHolder: "",
  copyrightYear: null,
  productionHolder: "",
  productionYear: null,
};

const artFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_ART_FORM":
      return initialState;
    case "SET_ART_MUSIC_TYPE_FORM":
      return {
        ...state,
        musicType: action.payload,
      };
    case "SET_ART_COMPRESSED_COVER_IMAGE_FORM":
      return {
        ...state,
        compressedCoverImage: action.payload,
      };
    case "SET_ART_ORIGINAL_COVER_IMAGE_FORM":
      return {
        ...state,
        originalCoverImage: action.payload,
      };
    case "SET_ART_TITLE_FORM":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_ART_COPYRIGHT_HOLDER_FORM":
      return {
        ...state,
        copyrightHolder: action.payload,
      };
    case "SET_ART_COPYRIGHT_YEAR_FORM":
      return {
        ...state,
        copyrightYear: action.payload,
      };
    case "SET_ART_PRODUCTION_HOLDER_FORM":
      return {
        ...state,
        productionHolder: action.payload,
      };
    case "SET_ART_PRODUCTION_YEAR_FORM":
      return {
        ...state,
        productionYear: action.payload,
      };
    case "SET_ART_RELEASE_DATE_FORM":
      return {
        ...state,
        releaseDate: action.payload,
      };
    default:
      return state;
  }
};

export default artFormReducer;
