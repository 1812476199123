const initialState = [
  {
    serial: 1, // Add a unique identifier (serial) for each item
    title: "",
    featureArtist: "",
    songWriter: "",
    lyrics: "",
    category: null,
    file: "",
    previewFile: "",
    isExplicit: false,
  },
];

// Action Creator
export const setMusicReleaseProperty = (property, value, serial) => ({
  type: "SET_MUSIC_RELEASE_PROPERTY",
  payload: { property, value, serial },
});

// Action Creator
export const resetMusicReleases = () => ({
  type: "RESET_MUSIC_RELEASES",
});

// Action Creator
export const initNewMusicRelease = () => ({
  type: "INIT_NEW_MUSIC_RELEASE",
});

// Reducer
const musicReleasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_MUSIC_RELEASES":
      return initialState;
    case "INIT_NEW_MUSIC_RELEASE":
      const newSerial = state[state.length - 1].serial + 1;
      return [...state, { ...initialState[0], serial: newSerial }];
    case "SET_MUSIC_RELEASE_PROPERTY":
      const { property, value, serial } = action.payload;

      // Check if the serial is valid
      if (serial >= 1 && serial <= state.length) {
        // Create a new object for the updated item
        const updatedItem = {
          ...state.find((item) => item.serial === serial),
          [property]: value,
        };

        // Create a new array with the updated item
        return state.map((item) =>
          item.serial === serial ? updatedItem : item
        );
      }

      return state;

    default:
      return state;
  }
};

export default musicReleasesReducer;
