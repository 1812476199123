import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import useChartmetric from "@hooks/useChartmetric";
import { toast } from "react-toastify";
import youtubeLogo from "@assets/icons/youtube_logo.png";
import spotifyLogo from "@assets/icons/spotify_logo.png";
import Avatar from "@ui/Avatar";
import ArtistMetricsTable from "@components/ArtistMetricsTable";
import { Skeleton } from "@mui/material";
import ArtistMetricsTableSkeleton from "@components/ArtistMetricsTable/ArtistMetricsTableSkeleton";
import ArtistHeaderDataSkeleton from "./ArtistHeaderDataSkeleton";

const ArtistGrowth = ({ artistName }) => {
  const [artistHeaderData, setArtistHeaderData] = useState(null);
  const [artistMetricsTableData, setArtistMetricsTableData] = useState(null);
  const { getHeaderData, getMetricsTableData, getFullData } = useChartmetric();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedFullData = await getFullData(artistName);
        const fetchedHeaderData = getHeaderData(fetchedFullData);
        setArtistHeaderData(fetchedHeaderData);
        const fetchedArtistMetricsData = getMetricsTableData(fetchedFullData);
        setArtistMetricsTableData(fetchedArtistMetricsData);
      } catch (err) {
        // user is not authenticated
        return;
      }
    };

    fetchData();
  }, [artistName]);

  const formatNumberWithKOrMOrB = (number) => {
    if (typeof number !== "number" || isNaN(number)) {
      return "-";
    }

    if (Math.abs(number) >= 1e9) {
      return `+${(number / 1e9).toFixed(1)}B`;
    } else if (Math.abs(number) >= 1e6) {
      return `+${(number / 1e6).toFixed(1)}M`;
    } else if (Math.abs(number) >= 1e3) {
      return `+${(number / 1e3).toFixed(1)}K`;
    } else {
      return number.toString();
    }
  };

  return (
    <div className={`${styles.details_container} container`}>
      <div style={{ marginTop: "5vh" }}>
        <h2 className={styles.title}>
          {artistName}
          's Growth
        </h2>
        <p style={{ opacity: 0.75, marginBottom: "2vh" }}>
          As of 1st January 2024
        </p>
        {artistHeaderData ? (
          <div className={styles.main_creator}>
            <div className={`${styles.dark_block} border-10`}>
              <Avatar src={spotifyLogo} alt={"Spotify"} size="sm" isVerified />
              <div className={styles.block_details}>
                <span className="text-xs">Total Monthly Listeners</span>
                <h3 className="text-md text-bold text-light">
                  {formatNumberWithKOrMOrB(
                    artistHeaderData?.currSpotifyMonthlyListeners
                  )}
                </h3>
              </div>
            </div>
            <div className={`${styles.dark_block} border-10`}>
              <Avatar src={youtubeLogo} alt={"Youtube"} size="sm" isVerified />
              <div className={styles.block_details}>
                <span className="text-xs">Total Views</span>
                <h3 className="text-md text-bold text-light">
                  {formatNumberWithKOrMOrB(artistHeaderData?.currYoutubeViews)}
                </h3>
              </div>
            </div>
            <div className={`${styles.dark_block} border-10`}>
              <Avatar src={spotifyLogo} alt={"Spotify"} size="sm" isVerified />
              <div className={styles.block_details}>
                <span className="text-xs">Total Followers</span>
                <h3 className="text-md text-bold text-light">
                  {formatNumberWithKOrMOrB(
                    artistHeaderData?.currSpotifyFollowers
                  )}
                </h3>
              </div>
            </div>
            <div className={`${styles.dark_block} border-10`}>
              <Avatar src={youtubeLogo} alt={"Youtube"} size="sm" isVerified />
              <div className={styles.block_details}>
                <span className="text-xs">Total Subscribers</span>
                <h3 className="text-md text-bold text-light">
                  {formatNumberWithKOrMOrB(artistHeaderData?.currYoutubeSubs)}
                </h3>
              </div>
            </div>
          </div>
        ) : (
          <ArtistHeaderDataSkeleton />
        )}
      </div>

      <div style={{ marginTop: "7vh" }}>
        <h3 style={{ color: "gray", marginBottom: "2vh" }}>Statistics</h3>
        {artistMetricsTableData ? (
          <ArtistMetricsTable artistMetricsTableData={artistMetricsTableData} />
        ) : (
          <ArtistMetricsTableSkeleton />
        )}
      </div>
    </div>
  );
};

export default ArtistGrowth;
