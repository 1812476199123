import React, { useEffect, useState } from "react";
// styling
import styles from "./style.module.scss";
import useChartmetric from "@hooks/useChartmetric";
import LatestAlbum from "./LatestAlbum";
import { Grid } from "@mui/material";
import LatestAlbumsSkeleton from "./LatestAlbumsSkeleton";

const LatestAlbums = ({ artistName }) => {
  const [latestAlbums, setLatestAlbums] = useState(null);
  const { getLatestAlbumsByArtistName } = useChartmetric();

  useEffect(() => {
    const fetchLatestAlbums = async () => {
      try {
        const fetchedLatestAlbums = await getLatestAlbumsByArtistName(
          artistName
        );
        const slicedFetchedLatestAlbums =
          fetchedLatestAlbums?.length > 0
            ? fetchedLatestAlbums.slice(0, 3)
            : [];
        console.log(slicedFetchedLatestAlbums);
        setLatestAlbums(slicedFetchedLatestAlbums);
      } catch (error) {
        // user is not authenticated
        return;
      }
    };

    fetchLatestAlbums();
  }, [artistName]);

  return (
    <div className={`${styles.details_container} container`}>
      <div style={{ marginTop: "5vh" }}>
        <h4 style={{ marginBottom: "5vh" }}>
          {artistName}
          's Latest Albums
        </h4>
        {latestAlbums ? (
          <Grid container display={"flex"} spacing={2} alignItems={"center"}>
            {latestAlbums.map((album) => (
              <LatestAlbum album={album} key={album.upc} />
            ))}
          </Grid>
        ) : (
          <LatestAlbumsSkeleton />
        )}
      </div>
    </div>
  );
};

export default LatestAlbums;
