import { createContext, useState, useContext, useEffect } from "react";
import useScrollLock from "@hooks/useScrollLock";
import { useSelector } from "react-redux";

export const RegistrationModalContext = createContext(undefined);

export const RegistrationPhase = {
  USERNAME: 1,
  EMAIL: 2,
  REFERRER: 3,
  COMPLETED: 4,
};

export const RegistrationModalContextAPI = ({ children }) => {
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [registrationPhase, setRegistrationPhase] = useState(
    RegistrationPhase.USERNAME
  );
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    isRegistrationModalOpen ? lockScroll() : unlockScroll();

    return () => {
      unlockScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegistrationModalOpen]);

  const openRegistrationModal = (phase) => {
    setRegistrationPhase(phase);
    setIsRegistrationModalOpen(true);
  };

  const closeRegistrationModal = () => setIsRegistrationModalOpen(false);

  return (
    <RegistrationModalContext.Provider
      value={{
        registrationPhase,
        isRegistrationModalOpen,
        openRegistrationModal,
        closeRegistrationModal,
      }}
    >
      {children}
    </RegistrationModalContext.Provider>
  );
};

export const useRegistrationModalContext = () =>
  useContext(RegistrationModalContext);
