import { animated, useSpring } from "@react-spring/web";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import classNames from "classnames";

const Wrapper = ({ tag, href, ...props }) => {
  if (tag === "button") {
    return <animated.button {...props} />;
  }

  if (href === undefined || href === "#") {
    return <animated.a {...props} />;
  }

  const AnimatedNavLink = animated(NavLink);
  return <AnimatedNavLink to={href} {...props} />;
};

const GradientBtn = ({
  className,
  submit = false,
  disabled = false,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const variants = {
    static: {
      background: "linear-gradient(98.49deg, #06ec0e -51.31%, #07960c 76.26%)",
    },
    active: {
      background: "linear-gradient(98.49deg, #07960c -51.31%, #06ec0e 76.26%)",
    },
    submit: {
      background: "linear-gradient(98.49deg, #0065c4 -51.31%, #2a80d1 76.26%)", // Bluish background for active
    },
    activeSubmit: {
      background: "linear-gradient(98.49deg, #2a80d1 -51.31%, #0065c4 76.26%)",
    },
  };

  const { background } = useSpring({
    background:
      isHovered && submit
        ? variants.activeSubmit.background
        : submit
        ? variants.submit.background
        : isHovered
        ? variants.active.background
        : variants.static.background,
    config: { duration: 300 },
    ...props,
  });

  return (
    <Wrapper
      className={classNames("btn btn--gradient", className)}
      {...props}
      disabled={disabled}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ background, opacity: disabled ? 0.2 : 1 }}
    >
      {props.children}
    </Wrapper>
  );
};

export default GradientBtn;
