export const CONNECTED_HEADER_LINKS = [
  //   {
  //     name: "Home",
  //     links: [
  //       { name: "Home", url: "/", isMain: true },
  //       { name: "Home 2", url: "/index2" },
  //     ],
  //   },
  {
    name: "Explore",
    isSingle: true,
    url: "/explore",
    // links: [
    //     {name: 'Explore', url: '/explore', isMain: true},
    //     {name: 'Explore 2', url: '/explore-grid'},
    //     {name: 'Single item', url: '/explore/item'},
    //     {name: 'User', url: '/user'},
    // ]
  },
  {
    name: "Dashboard",
    isSingle: true,
    url: "/dashboard",
  },
  {
    name: "Raise Funds",
    isSingle: true,
    url: "/raise-funds",
  },
  //   {
  //     name: "Community",
  //     links: [
  //       { name: "Blog Sidebar", url: "/blog-sidebar" },
  //       { name: "Blog Grid", url: "/blog-grid" },
  //       { name: "Single post", url: "/post" },
  //     ],
  //   },
  //   {
  //     name: "Contacts",
  //     isSingle: true,
  //     url: "/contacts",
  //   },
  {
    name: "Account",
    links: [
      { name: "Profile", url: "/profile" },
      { name: "Dashboard", url: "/dashboard" },
      { name: "Wallet", url: "/wallet" },
    ],
  },
];

export const CONNECTED_HEADER_LINKS_WITH_ADMIN = [
  {
    name: "Explore",
    isSingle: true,
    url: "/explore",
  },
  {
    name: "Dashboard",
    isSingle: true,
    url: "/dashboard",
  },
  {
    name: "Raise Funds",
    isSingle: true,
    url: "/raise-funds",
  },
  {
    name: "Admin",
    isSingle: true,
    url: "/admin",
  },
  {
    name: "Account",
    links: [
      { name: "Profile", url: "/profile" },
      { name: "Dashboard", url: "/dashboard" },
      { name: "Admin", url: "/admin" },
      {
        title: "Wallet",
        url: "/wallet",
      },
    ],
  },
];

export const CONNECTED_FOOTER_LINKS = [
  {
    title: "My account",
    links: [
      {
        title: "Profile",
        url: "/profile",
      },
      // {
      //   title: "My Collections",
      //   url: "/profile",
      // },
      {
        title: "Dashboard",
        url: "/dashboard",
      },
      // {
      //   title: "Settings",
      //   url: "/profile",
      // },
    ],
  },
  // {
  //   title: "Resources",
  //   links: [
  //     {
  //       title: "Help center",
  //       url: "/faq",
  //     },
  //     {
  //       title: "Activity",
  //       url: "/activity",
  //     },
  //     {
  //       title: "Ranking",
  //       url: "/ranking",
  //     },
  //     {
  //       title: "News",
  //       url: "/blog-grid",
  //     },
  //   ],
  // },
  // {
  //   title: "Company",
  //   links: [
  //     {
  //       title: "About",
  //       url: "/about",
  //     },
  //     {
  //       title: "Our team",
  //       url: "/team",
  //     },
  //     {
  //       title: "Blog",
  //       url: "/blog-sidebar",
  //     },
  //     {
  //       title: "Contact us",
  //       url: "/contacts",
  //     },
  //   ],
  // },
];
export const DISCONNECTED_FOOTER_LINKS = [
  {
    title: "My account",
    links: [
      {
        title: "Profile",
        url: "/profile",
      },
      // {
      //   title: "My Collections",
      //   url: "/profile",
      // },
      {
        title: "Connect",
        url: "/login",
      },
      // {
      //   title: "Settings",
      //   url: "/profile",
      // },
    ],
  },
  // {
  //   title: "Resources",
  //   links: [
  //     {
  //       title: "Help center",
  //       url: "/faq",
  //     },
  //     {
  //       title: "Activity",
  //       url: "/activity",
  //     },
  //     {
  //       title: "Ranking",
  //       url: "/ranking",
  //     },
  //     {
  //       title: "News",
  //       url: "/blog-grid",
  //     },
  //   ],
  // },
  // {
  //   title: "Company",
  //   links: [
  //     {
  //       title: "About",
  //       url: "/about",
  //     },
  //     {
  //       title: "Our team",
  //       url: "/team",
  //     },
  //     {
  //       title: "Blog",
  //       url: "/blog-sidebar",
  //     },
  //     {
  //       title: "Contact us",
  //       url: "/contacts",
  //     },
  //   ],
  // },
];

export const SOCIAL_LINKS = [
  {
    icon: "twitter",
    url: "https://twitter.com/Probal_Labs",
    name: "Twitter",
  },
  {
    icon: "instagram",
    url: "https://instagram.com/probal_labs/",
    name: "Instagram",
  },
  {
    icon: "linkedin",
    url: "https://linkedin.com/pro-capital-io",
    name: "Linkedin",
  },
];

export const PROFILE_SOCIAL_LINKS = [
  {
    icon: "twitter",
    url: "https://twitter.com/Probal_Labs",
    name: "Twitter",
  },
  {
    icon: "instagram",
    url: "https://instagram.com/probal_labs/",
    name: "Instagram",
  },
  {
    icon: "linkedin",
    url: "https://linkedin.com/pro-capital-io",
    name: "Linkedin",
  },
];
