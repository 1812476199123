// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const MusicPlatform = {
  "SPOTIFY": "SPOTIFY",
  "APPLE_MUSIC": "APPLE_MUSIC",
  "YOUTUBE_MUSIC": "YOUTUBE_MUSIC"
};

const NftRarity = {
  "COPPER": "COPPER",
  "BRONZE": "BRONZE",
  "SILVER": "SILVER",
  "GOLD": "GOLD",
  "PLATINUM": "PLATINUM",
  "DIAMOND": "DIAMOND"
};

const RaiseFundsApprovalStatus = {
  "DECLINED": "DECLINED",
  "NOT_PERMITTED": "NOT_PERMITTED",
  "REQUESTED": "REQUESTED",
  "PERMITTED": "PERMITTED"
};

const UserRole = {
  "ADMIN": "ADMIN",
  "VERIFIED_MEMBER": "VERIFIED_MEMBER",
  "MEMBER": "MEMBER"
};

const ApprovalStatus = {
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED"
};

const ReleaseStatus = {
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "SUSPENDED": "SUSPENDED",
  "CANCELLED": "CANCELLED",
  "ERROR": "ERROR"
};

const { User, NftMetadata, Artist, Holder, Project, Art, MusicRelease, MusicRoyalty, Holding, DistributionTransaction } = initSchema(schema);

export {
  User,
  NftMetadata,
  Artist,
  Holder,
  Project,
  Art,
  MusicRelease,
  MusicRoyalty,
  Holding,
  DistributionTransaction,
  MusicPlatform,
  NftRarity,
  RaiseFundsApprovalStatus,
  UserRole,
  ApprovalStatus,
  ReleaseStatus
};