import {
  createUser,
  updateUser,
  createProject,
  updateProject,
  createArt,
  createArtist,
  updateArtist,
  createHolder,
  updateHolder,
  createHolding,
  createNftMetadata,
  createMusicRelease,
  updateArt,
  updateMusicRelease,
} from "@graphql/mutations";
import {
  getUser,
  listArtists,
  listArts,
  listHolders,
  listMusicReleases,
  listMusicRoyalties,
  listNftMetadata,
  usersByUsername,
} from "@graphql/queries";
import {
  getDetailedProjectQuery,
  getExploreProjectsQuery,
  getHolderHoldingsQuery,
  getProjectsByAdminQuery,
  getSummerizedProjectQuery,
  getTopArtistsQuery,
  getOwnUserProjectsQuery,
  getUsersAdminQuery,
  listHoldingsByProjectQuery,
  usersByPublicAddressQuery,
  getOwnUserArtsQuery,
  getArtIdByProjectQuery,
  getDetailedProjectByProjectArtIdQuery,
  listAllArtsQuery,
  previewMusicRelease,
} from "@helpers/appsync/customQueries";
import {
  ApprovalStatus,
  MusicPlatform,
  RaiseFundsApprovalStatus,
  UserRole,
} from "models";
import { graphqlOperation } from "@aws-amplify/api";
import { API } from "aws-amplify";

export const isUserAdmin = async (id) => {
  try {
    // If no existing user found, create the new user
    const user = await API.graphql(graphqlOperation(getUser, { id }));
    if (user?.data?.getUser?.id) {
      return user.data.getUser.role === UserRole.ADMIN;
    }
    return false;
  } catch (e) {
    throw new Error(e);
  }
};

const getUserHolder = async (id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listHolders, {
        filter: {
          holderUserId: { eq: id }, // Use "eq" for equality
        },
      })
    );
    const items = result?.data?.listHolders?.items;
    if (items?.length > 0) {
      return items[0];
    } else {
      return null;
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const retrieveUserHolder = async (userId) => {
  try {
    const retrievedHolder = await getUserHolder(userId);
    if (!retrievedHolder) {
      const newHolder = { holderUserId: userId };
      const holder = await createHolderMutation(newHolder);
      return holder?.data?.createHolder;
    }
    return retrievedHolder;
  } catch (e) {
    throw new Error(e);
  }
};

export const createUserMutation = async (newUser) => {
  try {
    // If no existing user found, create the new user
    await API.graphql(graphqlOperation(createUser, { input: newUser }));
  } catch (e) {
    throw new Error(e);
  }
};

export const findUserByPublicAddress = async (publicAddress) => {
  try {
    // Check if a user with the same public address already exists
    const result = await API.graphql(
      graphqlOperation(usersByPublicAddressQuery, { publicAddress })
    );
    const users = result.data.usersByPublicAddress.items;
    if (users?.length > 0) {
      return users[0];
    }
    return null;
  } catch (e) {
    throw new Error(e);
  }
};

export const findUserByUsername = async (username) => {
  try {
    // Check if a user with the same public address already exists
    const result = await API.graphql(
      graphqlOperation(usersByUsername, { username })
    );
    const users = result.data.usersByUsername.items;
    if (users?.length > 0) {
      return users[0];
    }
    return null;
  } catch (e) {
    throw new Error(e);
  }
};

const getUserArtist = async (id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listArtists, {
        filter: {
          artistUserId: { eq: id },
        },
      })
    );
    const items = result?.data?.listArtists?.items;
    if (items?.length > 0) {
      return items[0];
    } else {
      return null;
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const getTopArtists = async () => {
  try {
    const result = await API.graphql(graphqlOperation(getTopArtistsQuery));
    const items = result?.data?.listUsers?.items;

    if (!items) {
      return [];
    }

    const artistPromises = items.map(async (item) => {
      const isArtist = await isUserArtist(item.id);
      if (isArtist) {
        return item;
      }
      return null;
    });

    const artists = await Promise.all(artistPromises);
    return artists.filter((item) => item !== null).slice(0, 6);
  } catch (e) {
    return [];
  }
};

export const isUserArtist = async (id) => {
  try {
    if (!id) {
      return false;
    }
    const retrievedArtist = await getUserArtist(id);
    return retrievedArtist != null ? true : false;
  } catch (e) {
    return false;
  }
};

export const isUserHolder = async (id) => {
  try {
    const retrievedHolder = await getUserHolder(id);
    return retrievedHolder ? true : false;
  } catch (e) {
    throw new Error(e);
  }
};

export const retrieveUserArtist = async (userId) => {
  try {
    const retrievedArtist = await getUserArtist(userId);
    if (!retrievedArtist) {
      const newArtist = { artistUserId: userId };
      const artist = await createArtistMutation(newArtist);
      return artist.data.createArtist;
    }
    return retrievedArtist;
  } catch (e) {
    throw new Error(e);
  }
};

export const updateUserMutation = async (updatedUser) => {
  try {
    const { role, raiseFundsApprovalStatus } = await getOtherUserDetails(
      updatedUser.id
    );
    const updatedUserWithOtherDetails = {
      role,
      raiseFundsApprovalStatus,
      ...updatedUser,
    };
    await API.graphql(
      graphqlOperation(updateUser, { input: updatedUserWithOtherDetails })
    );
  } catch (e) {
    throw new Error(e);
  }
};

// get UserRole And RaiseFundsApprovalStatus
const getOtherUserDetails = async (id) => {
  try {
    const user = await API.graphql(graphqlOperation(getUser, { id }));
    if (user?.data?.getUser?.id) {
      const role = user?.data?.getUser?.role;
      const raiseFundsApprovalStatus =
        user?.data?.getUser?.raiseFundsApprovalStatus;
      return { role, raiseFundsApprovalStatus };
    }
    return null;
  } catch (e) {
    throw new Error(e);
  }
};

export const createProjectMutation = async (newProject) => {
  try {
    return await API.graphql(
      graphqlOperation(createProject, { input: newProject })
    );
  } catch (e) {
    throw new Error(e);
  }
};

export const getUserById = async (id) => {
  try {
    const user = await API.graphql(graphqlOperation(getUser, { id }));
    return user?.data?.getUser;
  } catch (e) {
    throw new Error(e);
  }
};

export const getExploreProjects = async () => {
  try {
    const currentDate = new Date(); // Get the current date
    currentDate.setHours(23, 59, 59, 999); // Set it to the end of the day
    const result = await API.graphql(
      graphqlOperation(getExploreProjectsQuery, {
        filter: {
          approvalStatus: { eq: ApprovalStatus.APPROVED }, // Approved status
          endRaisingDate: { ge: currentDate }, // end raising date is greater than or equal to the current date
          startRaisingDate: { le: currentDate },
        },
      })
    );
    return result?.data?.listProjects?.items;
  } catch (e) {
    return [];
  }
};

export const getProjectsByFundraiser = async (fundraiserId) => {
  try {
    const currentDate = new Date(); // Get the current date
    currentDate.setHours(23, 59, 59, 999); // Set it to the end of the day
    const result = await API.graphql(
      graphqlOperation(getExploreProjectsQuery, {
        filter: {
          approvalStatus: { eq: ApprovalStatus.APPROVED }, // Approved status
          projectFundraiserId: { eq: fundraiserId },
        },
      })
    );
    return result?.data?.listProjects?.items;
  } catch (e) {
    return [];
  }
};

export const getUserByRaiseFundsApprovalStatusAdmin = async (status) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getUsersAdminQuery, {
        filter: {
          raiseFundsApprovalStatus: { eq: status }, // Use "eq" for equality
        },
      })
    );
    return result?.data?.listUsers?.items;
  } catch (e) {
    console.log("getUserByRaiseFundsApprovalStatusAdmin Query: ", e);
    throw new Error(e);
  }
};

export const getProjectsByApprovalStatusAdmin = async (status) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getProjectsByAdminQuery, {
        filter: {
          approvalStatus: { eq: status }, // Use "eq" for equality
        },
      })
    );
    return result?.data?.listProjects?.items;
  } catch (e) {
    console.log("getProjectsByAdmin Query: ", e);
    throw new Error(e);
  }
};

/**
 * The function `getAllArtsAdmin` retrieves all arts items from a GraphQL API and returns them as an
 * array, or an empty array if an error occurs.
 * @returns The function `getAllArtsAdmin` is returning an array of items from the `listArts` query
 * result. If the query is successful, it will return the `items` property of the `listArts` data. If
 * the query fails, it will return an empty array.
 */
export const getAllArtsAdmin = async () => {
  try {
    const result = await API.graphql(graphqlOperation(listAllArtsQuery));
    return result?.data?.listArts?.items;
  } catch (e) {
    return [];
  }
};

export const getDetailedProject = async (id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getDetailedProjectQuery, { id })
    );
    return result.data.getProject;
  } catch (e) {
    console.log("getDetailedProject Query: ", e);
    throw new Error(e);
  }
};

const getArtIdByArtTitle = async (title) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listArts, {
        filter: {
          title: { eq: title },
        },
      })
    );
    const items = result?.data?.listArts.items;
    if (items?.length > 0) {
      return items[0].id;
    } else {
      return null;
    }
  } catch (e) {
    console.log("getArtIdByArtTitle Query: ", e);
    throw new Error(e);
  }
};

export const getDetailedProjectByTitle = async (title) => {
  try {
    const artId = await getArtIdByArtTitle(title);
    const result = await API.graphql(
      graphqlOperation(getDetailedProjectByProjectArtIdQuery, {
        filter: {
          projectArtId: { eq: artId },
        },
      })
    );
    const items = result?.data?.listProjects?.items;
    if (items?.length > 0) {
      return items[0];
    } else {
      return null;
    }
  } catch (e) {
    console.log("getDetailedProjectByProjectArtIdQuery Query: ", e);
    throw new Error(e);
  }
};

export const getSummerizedProject = async (id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getSummerizedProjectQuery, { id })
    );
    return result?.data?.getProject;
  } catch (e) {
    console.log("getDetailedProject Query: ", e);
    throw new Error(e);
  }
};

export const getHoldingsOfProject = async (id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listHoldingsByProjectQuery, {
        filter: {
          holdingProjectId: { eq: id }, // Use "eq" for equality
        },
      })
    );
    return result.data.listHoldings.items;
  } catch (e) {
    console.log("getHoldingsOfProject Query: ", e);
    throw new Error(e);
  }
};

export const getMusicReleasesOfArt = async (id) => {
  try {
    let musicReleases = [];
    const result = await API.graphql(
      graphqlOperation(listMusicReleases, {
        filter: {
          musicReleaseArtId: { eq: id }, // Use "eq" for equality
        },
      })
    );
    musicReleases = result?.data?.listMusicReleases?.items;
    console.log(musicReleases);
    return musicReleases;
  } catch (e) {
    return [];
  }
};

export const getPreviewMusicRelease = async (id) => {
  try {
    let musicReleases = [];
    const result = await API.graphql(
      graphqlOperation(previewMusicRelease, {
        filter: {
          musicReleaseArtId: { eq: id },
        },
      })
    );
    musicReleases = result?.data?.listMusicReleases?.items;
    return musicReleases[0]?.previewFile;
  } catch (e) {
    return null;
  }
};

export const isArtAlbum = async (id) => {
  try {
    let musicReleasesLength = 0;
    const result = await API.graphql(
      graphqlOperation(listMusicReleases, {
        filter: {
          musicReleaseArtId: { eq: id }, // Use "eq" for equality
        },
      })
    );
    musicReleasesLength = result?.data?.listMusicReleases?.items?.length;
    return musicReleasesLength > 1; // more than 1 music release -> album
  } catch (e) {
    return 0;
  }
};

export const getNftMetadataByProjectAndRarity = async (projectId, rarity) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listNftMetadata, {
        filter: {
          nftMetadataProjectId: { eq: projectId },
          rarity: { eq: rarity },
        },
      })
    );
    const items = result?.data?.listNftMetadata?.items;
    if (items?.length > 0) {
      return items[0];
    } else {
      return null;
    }
  } catch (e) {
    console.log("getNftMetadataByProjectAndRarity Query: ", e);
    throw new Error(e);
  }
};

export const updateProjectMutation = async (updatedProject) => {
  try {
    await API.graphql({
      query: updateProject,
      variables: { input: updatedProject },
    });
  } catch (e) {
    console.log("updateProjectMutation: ", e);
    throw new Error(e);
  }
};

export const updateArtMutation = async (updatedArt) => {
  try {
    await API.graphql({
      query: updateArt,
      variables: { input: updatedArt },
    });
  } catch (e) {
    console.log("updateArtMutation: ", e);
    throw new Error(e);
  }
};

export const updateMusicReleasesStatusesMutation = async (
  art,
  releaseStatus
) => {
  try {
    const updatedMusicReleases = art.musicReleases.items.map((musicRelease) => {
      return {
        id: musicRelease.id,
        releaseStatus: releaseStatus,
      };
    });

    // Create an array of promises for each mutation
    const mutationPromises = updatedMusicReleases.map((updatedMusicRelease) => {
      return updateMusicReleaseMutation(updatedMusicRelease);
    });

    // Use Promise.all to execute all mutations in parallel
    await Promise.all(mutationPromises);
  } catch (error) {
    console.error("Error updating music releases statuses:", error);
    throw new Error(error);
  }
};

export const updateMusicReleaseMutation = async (updatedMusicRelease) => {
  try {
    await API.graphql({
      query: updateMusicRelease,
      variables: { input: updatedMusicRelease },
    });
  } catch (e) {
    console.log("updateMusicReleaseMutation: ", e);
    throw new Error(e);
  }
};

export const createMusicReleaseMutation = async (newMusicRelease) => {
  try {
    return await API.graphql(
      graphqlOperation(createMusicRelease, { input: newMusicRelease })
    );
  } catch (e) {
    console.log("createMusicReleaseMutation: ", e);
    throw new Error(e);
  }
};

export const createArtMutation = async (newArt) => {
  try {
    return await API.graphql(graphqlOperation(createArt, { input: newArt }));
  } catch (e) {
    console.log("createArtMutation: ", e);
    throw new Error(e);
  }
};

export const createArtistMutation = async (newArtist) => {
  try {
    return await API.graphql(
      graphqlOperation(createArtist, { input: newArtist })
    );
  } catch (e) {
    console.log("createArtistMutation: ", e);
    throw new Error(e);
  }
};

export const updateArtistMutation = async (updatedArtist) => {
  try {
    await API.graphql({
      query: updateArtist,
      variables: { input: updatedArtist },
    });
  } catch (e) {
    console.log("updateArtistMutation: ", e);
    throw new Error(e);
  }
};

export const sendRaiseFundsAllowneceRequest = async (id) => {
  try {
    const updatedUser = {
      id,
      raiseFundsApprovalStatus: RaiseFundsApprovalStatus.REQUESTED,
    };
    await API.graphql({
      query: updateUser,
      variables: { input: updatedUser },
    });
  } catch (e) {
    console.log("sendRaiseFundsAllowneceRequest: ", e);
    throw new Error(e);
  }
};

export const getUserRaiseFundsApprovalStatus = async (id) => {
  try {
    // If no existing user found, create the new user
    const user = await API.graphql(graphqlOperation(getUser, { id }));
    if (user?.data?.getUser?.raiseFundsApprovalStatus) {
      return user.data.getUser.raiseFundsApprovalStatus;
    }
    return RaiseFundsApprovalStatus.NOT_PERMITTED;
  } catch (e) {
    console.log("getUserRaiseFundsApprovalStatus: ", e);
    throw new Error(e);
  }
};

export const getOwnUserProjects = async (id) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getOwnUserProjectsQuery, {
        filter: {
          projectFundraiserId: { eq: id },
        },
      })
    );

    return result?.data?.listProjects?.items;
  } catch (e) {
    console.log("getOwnUserProjects Query: ", e);
    throw new Error(e);
  }
};

export const getOwnUserArts = async (artistId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getOwnUserArtsQuery, {
        filter: {
          artArtistId: { eq: artistId },
        },
      })
    );

    return result?.data?.listArts?.items;
  } catch (e) {
    console.log("getOwnUserArts Query: ", e);
    throw new Error(e);
  }
};

// This function returns the holdings of a specific holder by the given id
export const getOwnHolderHoldings = async (id) => {
  try {
    let holdings = [];
    const result = await API.graphql(
      graphqlOperation(getHolderHoldingsQuery, {
        filter: {
          holdingHolderId: { eq: id },
        },
      })
    );
    holdings = result?.data?.listHoldings?.items;
    return holdings;
  } catch (e) {
    return [];
  }
};

// This function returns the art by the given project id
const getArtIdByProjectId = async (id) => {
  try {
    const artIdQueryResult = await API.graphql(
      graphqlOperation(getArtIdByProjectQuery, { id })
    );
    return artIdQueryResult?.data?.getProject?.art?.id;
  } catch (e) {
    throw new Error(e);
  }
};

// This function returns the art by the given art id
const listMusicReleasesByArt = async (artId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(listMusicReleases, {
        filter: {
          musicReleaseArtId: { eq: artId },
        },
      })
    );

    return result?.data?.listMusicReleases?.items;
  } catch (e) {
    throw new Error(e);
  }
};
export const getRoyaltiesListsByProject = async (projectId) => {
  try {
    const artId = await getArtIdByProjectId(projectId);
    return await getRoyaltiesListsByArt(artId);
  } catch (e) {
    console.log("getRoyaltiesListsByArt Query: ", e);
    throw new Error(e);
  }
};
// This function returns the royalties list of a specific project by the given art id
export const getRoyaltiesListsByArt = async (artId) => {
  try {
    const musicReleases = await listMusicReleasesByArt(artId);

    const spotifyArray = [];
    const youtubeMusicArray = [];
    const appleMusicArray = [];

    for (const musicRelease of musicReleases) {
      const result = await API.graphql(
        graphqlOperation(listMusicRoyalties, {
          filter: {
            musicRoyaltyMusicReleaseId: { eq: musicRelease.id },
          },
        })
      );

      const items = result?.data?.listMusicRoyalties?.items;

      if (items && items.length > 0) {
        items.forEach((item) => {
          switch (item.platform) {
            case MusicPlatform.SPOTIFY:
              spotifyArray.push(item);
              break;
            case MusicPlatform.YOUTUBE_MUSIC:
              youtubeMusicArray.push(item);
              break;
            case MusicPlatform.APPLE_MUSIC:
              appleMusicArray.push(item);
              break;
            default:
              break;
          }
        });
      }
    }

    return {
      spotifyArray,
      youtubeMusicArray,
      appleMusicArray,
    };
  } catch (e) {
    console.log("getRoyaltiesListsByArt Query: ", e);
    throw new Error(e);
  }
};

export const createHolderMutation = async (newHolder) => {
  try {
    return await API.graphql(
      graphqlOperation(createHolder, { input: newHolder })
    );
  } catch (e) {
    console.log("createHolderMutation: ", e);
    throw new Error(e);
  }
};

export const updateHolderMutation = async (updatedHolder) => {
  try {
    await API.graphql({
      query: updateHolder,
      variables: { input: updatedHolder },
    });
  } catch (e) {
    console.log("updateHolderMutation: ", e);
    throw new Error(e);
  }
};

export const createHoldingMutation = async (newHolding) => {
  try {
    await API.graphql(graphqlOperation(createHolding, { input: newHolding }));
  } catch (e) {
    console.log("createHoldingMutation: ", e);
    throw new Error(e);
  }
};

export const createNftMetadataMutation = async (newNftMetadata) => {
  try {
    await API.graphql(
      graphqlOperation(createNftMetadata, { input: newNftMetadata })
    );
  } catch (e) {
    console.log("createNftMetadataMutation: ", e);
    throw new Error(e);
  }
};
