// hooks
import { useState, useEffect } from "react";

// utils
import PropTypes from "prop-types";

const CollapsedText = ({
  text,
  lines = 2,
  className,
  withButton = false,
  width,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <p className={`text-xs`}>
      {expanded ? text : `${text.slice(0, 170)}...`}
      {text.length > 170 && (
        <button
          style={{ marginLeft: "5px", opacity: 0.85, color: "#00d107" }}
          onClick={handleToggleExpand}
        >
          {expanded ? "Read Less" : "Read More"}
        </button>
      )}
    </p>
  );
};

CollapsedText.propTypes = {
  text: PropTypes.string.isRequired,
  lines: PropTypes.number,
  className: PropTypes.string,
  withButton: PropTypes.bool,
  width: PropTypes.number.isRequired,
};

export default CollapsedText;
