import { createContext, useState, useContext, useEffect } from "react";
import useScrollLock from "@hooks/useScrollLock";

export const MintModalContext = createContext(undefined);

export const MintModalContextAPI = ({ children }) => {
  const [isMintModalOpen, setIsMintModalOpen] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    isMintModalOpen ? lockScroll() : unlockScroll();

    return () => {
      unlockScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMintModalOpen]);

  const openMintModal = () => setIsMintModalOpen(true);

  const closeMintModal = () => setIsMintModalOpen(false);

  return (
    <MintModalContext.Provider
      value={{ isMintModalOpen, openMintModal, closeMintModal }}
    >
      {children}
    </MintModalContext.Provider>
  );
};

export const useMintModalContext = () => useContext(MintModalContext);
