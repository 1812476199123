// components
import { Helmet } from "react-helmet";

const SEO = ({ title, description = "" }) => {
  return (
    <Helmet>
      <title style={{ fontFamily: "sans-serif", fontWeight: "bold" }}>
        {title} | Probal
      </title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SEO;
