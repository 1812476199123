/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      publicAddress
      username
      email
      name
      role
      raiseFundsApprovalStatus
      bio
      profileImage
      coverImage
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNftMetadata = /* GraphQL */ `
  mutation CreateNftMetadata(
    $input: CreateNftMetadataInput!
    $condition: ModelNftMetadataConditionInput
  ) {
    createNftMetadata(input: $input, condition: $condition) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const updateNftMetadata = /* GraphQL */ `
  mutation UpdateNftMetadata(
    $input: UpdateNftMetadataInput!
    $condition: ModelNftMetadataConditionInput
  ) {
    updateNftMetadata(input: $input, condition: $condition) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const deleteNftMetadata = /* GraphQL */ `
  mutation DeleteNftMetadata(
    $input: DeleteNftMetadataInput!
    $condition: ModelNftMetadataConditionInput
  ) {
    deleteNftMetadata(input: $input, condition: $condition) {
      id
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      url
      compressedImage
      rarity
      createdAt
      updatedAt
      nftMetadataProjectId
      __typename
    }
  }
`;
export const createArtist = /* GraphQL */ `
  mutation CreateArtist(
    $input: CreateArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    createArtist(input: $input, condition: $condition) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const updateArtist = /* GraphQL */ `
  mutation UpdateArtist(
    $input: UpdateArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    updateArtist(input: $input, condition: $condition) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const deleteArtist = /* GraphQL */ `
  mutation DeleteArtist(
    $input: DeleteArtistInput!
    $condition: ModelArtistConditionInput
  ) {
    deleteArtist(input: $input, condition: $condition) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      artistUserId
      __typename
    }
  }
`;
export const createHolder = /* GraphQL */ `
  mutation CreateHolder(
    $input: CreateHolderInput!
    $condition: ModelHolderConditionInput
  ) {
    createHolder(input: $input, condition: $condition) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const updateHolder = /* GraphQL */ `
  mutation UpdateHolder(
    $input: UpdateHolderInput!
    $condition: ModelHolderConditionInput
  ) {
    updateHolder(input: $input, condition: $condition) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const deleteHolder = /* GraphQL */ `
  mutation DeleteHolder(
    $input: DeleteHolderInput!
    $condition: ModelHolderConditionInput
  ) {
    deleteHolder(input: $input, condition: $condition) {
      id
      user {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      holderUserId
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      fundraiser {
        id
        publicAddress
        username
        email
        name
        role
        raiseFundsApprovalStatus
        bio
        profileImage
        coverImage
        referrer
        createdAt
        updatedAt
        __typename
      }
      amountRaising
      sharedPercentage
      amountRaised
      startRaisingDate
      endRaisingDate
      description
      approvalStatus
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      holdings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      projectFundraiserId
      projectArtId
      __typename
    }
  }
`;
export const createArt = /* GraphQL */ `
  mutation CreateArt(
    $input: CreateArtInput!
    $condition: ModelArtConditionInput
  ) {
    createArt(input: $input, condition: $condition) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const updateArt = /* GraphQL */ `
  mutation UpdateArt(
    $input: UpdateArtInput!
    $condition: ModelArtConditionInput
  ) {
    updateArt(input: $input, condition: $condition) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const deleteArt = /* GraphQL */ `
  mutation DeleteArt(
    $input: DeleteArtInput!
    $condition: ModelArtConditionInput
  ) {
    deleteArt(input: $input, condition: $condition) {
      id
      title
      compressedCoverImage
      originalCoverImage
      artist {
        id
        createdAt
        updatedAt
        artistUserId
        __typename
      }
      musicReleases {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      artArtistId
      __typename
    }
  }
`;
export const createMusicRelease = /* GraphQL */ `
  mutation CreateMusicRelease(
    $input: CreateMusicReleaseInput!
    $condition: ModelMusicReleaseConditionInput
  ) {
    createMusicRelease(input: $input, condition: $condition) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const updateMusicRelease = /* GraphQL */ `
  mutation UpdateMusicRelease(
    $input: UpdateMusicReleaseInput!
    $condition: ModelMusicReleaseConditionInput
  ) {
    updateMusicRelease(input: $input, condition: $condition) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const deleteMusicRelease = /* GraphQL */ `
  mutation DeleteMusicRelease(
    $input: DeleteMusicReleaseInput!
    $condition: ModelMusicReleaseConditionInput
  ) {
    deleteMusicRelease(input: $input, condition: $condition) {
      id
      art {
        id
        title
        compressedCoverImage
        originalCoverImage
        createdAt
        updatedAt
        artArtistId
        __typename
      }
      releaseStatus
      category
      title
      releaseDate
      file
      previewFile
      featureArtist
      isExplicit
      copyrightHolder
      copyrightYear
      productionHolder
      productionYear
      songWriter
      lyrics
      createdAt
      updatedAt
      musicReleaseArtId
      __typename
    }
  }
`;
export const createMusicRoyalty = /* GraphQL */ `
  mutation CreateMusicRoyalty(
    $input: CreateMusicRoyaltyInput!
    $condition: ModelMusicRoyaltyConditionInput
  ) {
    createMusicRoyalty(input: $input, condition: $condition) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const updateMusicRoyalty = /* GraphQL */ `
  mutation UpdateMusicRoyalty(
    $input: UpdateMusicRoyaltyInput!
    $condition: ModelMusicRoyaltyConditionInput
  ) {
    updateMusicRoyalty(input: $input, condition: $condition) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const deleteMusicRoyalty = /* GraphQL */ `
  mutation DeleteMusicRoyalty(
    $input: DeleteMusicRoyaltyInput!
    $condition: ModelMusicRoyaltyConditionInput
  ) {
    deleteMusicRoyalty(input: $input, condition: $condition) {
      id
      platform
      musicRelease {
        id
        releaseStatus
        category
        title
        releaseDate
        file
        previewFile
        featureArtist
        isExplicit
        copyrightHolder
        copyrightYear
        productionHolder
        productionYear
        songWriter
        lyrics
        createdAt
        updatedAt
        musicReleaseArtId
        __typename
      }
      amountGenerated
      date
      createdAt
      updatedAt
      musicRoyaltyMusicReleaseId
      __typename
    }
  }
`;
export const createHolding = /* GraphQL */ `
  mutation CreateHolding(
    $input: CreateHoldingInput!
    $condition: ModelHoldingConditionInput
  ) {
    createHolding(input: $input, condition: $condition) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const updateHolding = /* GraphQL */ `
  mutation UpdateHolding(
    $input: UpdateHoldingInput!
    $condition: ModelHoldingConditionInput
  ) {
    updateHolding(input: $input, condition: $condition) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const deleteHolding = /* GraphQL */ `
  mutation DeleteHolding(
    $input: DeleteHoldingInput!
    $condition: ModelHoldingConditionInput
  ) {
    deleteHolding(input: $input, condition: $condition) {
      id
      holder {
        id
        createdAt
        updatedAt
        holderUserId
        __typename
      }
      project {
        id
        amountRaising
        sharedPercentage
        amountRaised
        startRaisingDate
        endRaisingDate
        description
        approvalStatus
        createdAt
        updatedAt
        projectFundraiserId
        projectArtId
        __typename
      }
      amountInvested
      transactionId
      createdAt
      updatedAt
      holdingHolderId
      holdingProjectId
      __typename
    }
  }
`;
export const createDistributionTransaction = /* GraphQL */ `
  mutation CreateDistributionTransaction(
    $input: CreateDistributionTransactionInput!
    $condition: ModelDistributionTransactionConditionInput
  ) {
    createDistributionTransaction(input: $input, condition: $condition) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
export const updateDistributionTransaction = /* GraphQL */ `
  mutation UpdateDistributionTransaction(
    $input: UpdateDistributionTransactionInput!
    $condition: ModelDistributionTransactionConditionInput
  ) {
    updateDistributionTransaction(input: $input, condition: $condition) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
export const deleteDistributionTransaction = /* GraphQL */ `
  mutation DeleteDistributionTransaction(
    $input: DeleteDistributionTransactionInput!
    $condition: ModelDistributionTransactionConditionInput
  ) {
    deleteDistributionTransaction(input: $input, condition: $condition) {
      id
      holding {
        id
        amountInvested
        transactionId
        createdAt
        updatedAt
        holdingHolderId
        holdingProjectId
        __typename
      }
      date
      amount
      isWithdrawn
      dateOfWithdrawal
      createdAt
      updatedAt
      distributionTransactionHoldingId
      __typename
    }
  }
`;
