import axios from "axios";
import { Auth } from "aws-amplify"; // Import the Auth module from AWS Amplify

const serverUrl = `${process.env.REACT_APP_API_URL}/api/chartmetric`; // Replace with your server URL

// Singleton Axios instance
let axiosInstance = null;

// Function to get the AWS Amplify authentication token
const getAuthToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  } catch (error) {
    throw error;
  }
};

// Function to create or get the Axios instance
const getAxiosInstance = async () => {
  try {
    if (!axiosInstance) {
      const authToken = await getAuthToken();
      axiosInstance = axios.create({
        baseURL: serverUrl,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      });
    }
    return axiosInstance;
  } catch (error) {
    throw error;
  }
};

// Function to send a POST request with the provided data
const getRequest = async (endpoint, config = {}) => {
  try {
    const axiosInstance = await getAxiosInstance(); // Get the Axios instance
    const response = await axiosInstance.get(endpoint, config); // Use the instance for the request
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getArtistById = async (id) => {
  try {
    const endpoint = `/artist/${id}`;
    return await getRequest(endpoint);
  } catch (error) {
    throw error;
  }
};

export const searchByName = async (name, limit = 10) => {
  try {
    await getAuthToken();
    const endpoint = `/search?q=${name}&limit=${limit}`;
    return await getRequest(endpoint);
  } catch (error) {
    throw error;
  }
};

export const getAlbumsByArtistId = async (id) => {
  try {
    const endpoint = `/artist/${id}/albums`;
    return await getRequest(endpoint);
  } catch (error) {
    throw error;
  }
};

export const getUrlsByArtistId = async (id) => {
  try {
    const endpoint = `/artist/${id}/urls`;
    return await getRequest(endpoint);
  } catch (error) {
    throw error;
  }
};

export const getStats = async (id, source, since, until) => {
  try {
    const endpoint =
      since && until
        ? `/artist/${id}/stat/${source}?since=${since}&until=${until}`
        : `/artist/${id}/stat/${source}`;
    return await getRequest(endpoint);
  } catch (error) {
    return {};
  }
};
