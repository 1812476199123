const initialState = [];

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROJECTS":
      return action.payload;
    case "UPDATE_PROJECT":
      // Find the project to update in the array
      const updatedProject = action.payload;
      const updatedProjects = state.map((project) =>
        project.id === updatedProject.id ? updatedProject : project
      );
      return updatedProjects;

    default:
      return state;
  }
};

export default projectsReducer;
