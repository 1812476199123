import {
  getAlbumsByArtistId,
  getStats,
  searchByName,
  getUrlsByArtistId,
} from "@helpers/api/chartmetric";

const useChartmetric = () => {
  const getUrlsByArtistName = async (artistName) => {
    if (!artistName) return null;
    const artistId = await fetchArtistIdByArtistName(artistName);
    const data = await getUrlsByArtistId(artistId);
    return data;
  };
  const getLatestAlbumsByArtistName = async (artistName) => {
    if (!artistName) return null;

    const artistId = await fetchArtistIdByArtistName(artistName);
    const data = await getAlbumsByArtistId(artistId);

    // Filter albums with num_track greater than 1
    const filteredAlbums = data.filter((album) => album.num_track > 1);
    // Ensure the first artist in each album has the same id as artistId
    const modifiedAlbums = filteredAlbums.filter(
      (album) => album?.artists[0]?.cm_artist == artistId
    );

    // Sort modified albums by sp_popularity in descending order, handling missing sp_popularity
    const sortedAlbumsByPopularity = modifiedAlbums.sort((a, b) => {
      const popularityA = a.sp_popularity || 0;
      const popularityB = b.sp_popularity || 0;
      return popularityB - popularityA;
    });

    const uniqueFilteredAlbums = sortedAlbumsByPopularity.reduce(
      (accumulator, album) => {
        const hasMix = /mix/i.test(album.name);

        if (!hasMix) {
          const duplicateIndex = accumulator.findIndex((existing) => {
            // Check if the current album name contains the existing album name or vice versa (case-insensitive)
            return (
              existing.name.toLowerCase().includes(album.name.toLowerCase()) ||
              album.name.toLowerCase().includes(existing.name.toLowerCase())
            );
          });

          if (duplicateIndex !== -1) {
            // Replace the existing album with the current one if the name is shorter
            if (album.name.length < accumulator[duplicateIndex].name.length) {
              accumulator[duplicateIndex] = album;
            }
          } else {
            // Add the current album to the accumulator if no duplicate is found
            accumulator.push(album);
          }
        }

        return accumulator;
      },
      []
    );

    return uniqueFilteredAlbums;
  };

  const getMetricsTableData = (fullData) => {
    if (!fullData) return null;
    const {
      youtubeSubscribersList = [],
      youtubeViewsList = [],
      spotifyFollowersList = [],
      spotifyMonthlyListenersList = [],
      soundcloudFollowersList = [],
      deezerFansList = [],
    } = concentrateFullData(fullData);

    const getEmptyMetricsObject = () => ({
      currYoutubeViews: "-",
      currYoutubeSubs: "-",
      currSpotifyFollowers: "-",
      currSpotifyMonthlyListeners: "-",
      currSoundcloudFollowers: "-",
      currDeezerFans: "-",
    });

    const data = {
      years: {
        2021: { ...getEmptyMetricsObject() },
        2022: { ...getEmptyMetricsObject() },
        2023: { ...getEmptyMetricsObject() },
      },
      quarters: {
        Q1: { ...getEmptyMetricsObject() },
        Q2: { ...getEmptyMetricsObject() },
        Q3: { ...getEmptyMetricsObject() },
        Q4: { ...getEmptyMetricsObject() },
      },
    };

    const isEndOfQuarter = (timestp) => {
      const date = new Date(timestp);
      const month = date.getMonth() + 1;
      return month % 3 === 0;
    };

    const isEndOfYear = (timestp) => {
      const date = new Date(timestp);
      const month = date.getMonth() + 1;
      return month >= 11;
    };

    const processList = (list, category) => {
      list.forEach(({ timestp, value }) => {
        const year = new Date(timestp).getFullYear();
        if (isEndOfQuarter(timestp)) {
          const quarter = Math.floor(new Date(timestp).getMonth() / 3) + 1;
          const key = `Q${quarter}`;
          data.quarters[key] = data.quarters[key] || {
            ...getEmptyMetricsObject(),
          };
          data.quarters[key][category] = value === 0 ? "-" : value;
        } else if (isEndOfYear(timestp)) {
          const key = year.toString();
          data.years[key] = data.years[key] || { ...getEmptyMetricsObject() };
          data.years[key][category] = value === 0 ? "-" : value;
        }
      });
    };

    processList(youtubeSubscribersList, "currYoutubeSubs");
    processList(youtubeViewsList, "currYoutubeViews");
    processList(spotifyFollowersList, "currSpotifyFollowers");
    processList(spotifyMonthlyListenersList, "currSpotifyMonthlyListeners");
    processList(soundcloudFollowersList, "currSoundcloudFollowers");
    processList(deezerFansList, "currDeezerFans");

    return data;
  };

  const concentrateFullData = (fullData) => {
    const fetchedYoutubeChannelStats = fullData[0]; // youtube channel stats
    const fetchedSpotifyArtistStats = fullData[1]; // Spotify artist stats
    const fetchedSoundcloudArtistStats = fullData[2]; // Soundcloud artist stats
    const fetchedDeezerArtistStats = fullData[3]; // Deezer artist stats

    const youtubeSubscribersList = fetchedYoutubeChannelStats?.subscribers;
    const youtubeViewsList = fetchedYoutubeChannelStats?.views;
    const spotifyFollowersList = fetchedSpotifyArtistStats?.followers;
    const spotifyMonthlyListenersList = fetchedSpotifyArtistStats?.listeners;
    const soundcloudFollowersList = fetchedSoundcloudArtistStats?.followers;
    const deezerFansList = fetchedDeezerArtistStats?.fans;

    return {
      youtubeSubscribersList,
      youtubeViewsList,
      spotifyFollowersList,
      spotifyMonthlyListenersList,
      soundcloudFollowersList,
      deezerFansList,
    };
  };

  const getHeaderData = (fullData) => {
    if (!fullData) return null;
    const {
      youtubeSubscribersList,
      youtubeViewsList,
      spotifyFollowersList,
      spotifyMonthlyListenersList,
      soundcloudFollowersList,
      deezerFansList,
    } = concentrateFullData(fullData);

    const currYoutubeViews =
      youtubeViewsList?.length > 0
        ? youtubeViewsList[youtubeViewsList?.length - 1].value
        : "-";
    const currYoutubeSubs =
      youtubeSubscribersList?.length > 0
        ? youtubeSubscribersList[youtubeSubscribersList?.length - 1].value
        : "-";

    const currSpotifyFollowers =
      spotifyFollowersList?.length > 0
        ? spotifyFollowersList[spotifyFollowersList?.length - 1].value
        : "-";
    const currSpotifyMonthlyListeners =
      spotifyMonthlyListenersList?.length > 0
        ? spotifyMonthlyListenersList[spotifyMonthlyListenersList?.length - 1]
            .value
        : "-";
    const currSoundcloudFollowers =
      soundcloudFollowersList?.length > 0
        ? soundcloudFollowersList[soundcloudFollowersList?.length - 1].value
        : "-";
    const currDeezerFans =
      deezerFansList?.[deezerFansList.length - 1]?.value === 0
        ? "-"
        : deezerFansList?.[deezerFansList.length - 1]?.value || "-";

    return {
      currYoutubeViews,
      currYoutubeSubs,
      currSpotifyFollowers,
      currSpotifyMonthlyListeners,
      currSoundcloudFollowers,
      currDeezerFans,
    };
  };

  const fetchArtistIdByArtistName = async (artistName) => {
    if (!artistName) return null;
    const result = await searchByName(artistName);
    const lowercaseArtistName = artistName.toLowerCase();
    const matchingArtists = result?.artists?.filter(
      (artist) =>
        artist.name && artist.name.toLowerCase() === lowercaseArtistName
    );

    const chosenArtist = matchingArtists?.reduce((prev, current) =>
      prev.sp_followers > current.sp_followers ? prev : current
    );

    return chosenArtist?.id || result?.artists[0]?.id;
  };

  const getFullData = async (artistName) => {
    const fullStats = [];
    const artistId = await fetchArtistIdByArtistName(artistName);
    const fetchedYoutubeChannelStats = await getStats(
      artistId,
      "youtube_channel",
      "2021-01-01",
      "2024-01-01"
    );

    const fetchedSpotifyArtistStats = await getStats(
      artistId,
      "spotify",
      "2021-01-01",
      "2024-01-01"
    );

    const fetchedSoundcloudArtistStats = await getStats(
      artistId,
      "soundcloud",
      "2021-01-01",
      "2024-01-01"
    );

    const fetchedDeezerArtistStats = await getStats(
      artistId,
      "deezer",
      "2021-01-01",
      "2024-01-01"
    );

    fullStats.push(fetchedYoutubeChannelStats);
    fullStats.push(fetchedSpotifyArtistStats);
    fullStats.push(fetchedSoundcloudArtistStats);
    fullStats.push(fetchedDeezerArtistStats);

    return fullStats;
  };

  return {
    getFullData,
    getHeaderData,
    getMetricsTableData,
    getLatestAlbumsByArtistName,
    getUrlsByArtistName,
  };
};

export default useChartmetric;
