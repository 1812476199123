import Title from "@components/Title";
import {
  findUserByUsername,
  GETa,
  getUserArtist,
  isUserArtist,
} from "@helpers/appsync/api";
import UserCard from "@layout/user/UserCard";
import UserItems from "@layout/user/UserItems";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ArtistCard from "@layout/artist/ArtistCard";
import ArtistItems from "@layout/artist/ArtistItems";

const Artist = () => {
  const navigate = useNavigate();
  const params = useParams();
  const username = params.username;
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    const getArtist = async () => {
      try {
        const fetchedArtist = await findUserByUsername(username);
        const isArtist = await isUserArtist(fetchedArtist?.id);
        if (isArtist) {
          setArtist(fetchedArtist);
        } else {
          navigate("/not-found");
        }
        // Update the user state when data is fetched
      } catch (error) {
        console.log("Error fetching user:", error);
        navigate("/not-found");
      }
    };

    getArtist();
  }, []);

  return (
    <>
      <Title title={`@${username}`} />
      <main>
        {artist ? (
          <>
            <ArtistCard artist={artist} />
            <ArtistItems artist={artist} />
          </>
        ) : (
          <></>
        )}
      </main>
    </>
  );
};

export default Artist;
