import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import GradientBtn from "@ui/GradientBtn";
import { toast } from "react-toastify";
import { useState } from "react";
import LoadingModal from "@components/LoadingModal";
import { ArrowBackIosNewTwoTone } from "@mui/icons-material";
import useMusicDistribution from "@hooks/form/useMusicDistribution";
import { useDispatch } from "react-redux";

const Distribution = ({ onBack, onComplete }) => {
  const [isFirstChecked, setFirstChecked] = useState(true);
  const [isSecondChecked, setSecondChecked] = useState(true);
  const [isThirdChecked, setThirdChecked] = useState(true);
  const [isFourthChecked, setFourthChecked] = useState(true);
  const { uploadMusic } = useMusicDistribution();

  const [loading, setLoading] = useState({
    isShown: false,
    message: "Loading",
  });

  const handleConfirm = async () => {
    try {
      setLoading({ isShown: true, message: "Sending Music For Approval..." });
      await uploadMusic();
      setLoading({ isShown: false, message: "" });
      toast.success("Music Sent For Approval!");
      onComplete();
    } catch (error) {
      setLoading({ isShown: false, message: "" });
      toast.error("Failed to upload Project!");
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} marginTop={1} marginBottom={1}>
        <Button
          onClick={onBack}
          sx={{ fontFamily: "var(--heading-font)", textTransform: "none" }}
          startIcon={<ArrowBackIosNewTwoTone />}
          color="primary"
        >
          Go Back
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} marginTop={1}>
        <h5>Distribution</h5>
      </Grid>
      <Grid item xs={12} sm={12} md={6} marginTop={1} padding={2}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFirstChecked}
                  onChange={(event, newValue) => setFirstChecked(newValue)}
                  color="primary"
                />
              }
              label={
                <p
                  style={{
                    opacity: 0.8,
                    fontSize: "0.65rem",
                    margin: 0,
                    padding: 0,
                    fontWeight: "lighter",
                  }}
                >
                  I understand my release may be rejected from stores if I don't
                  correctly label tracks as explicit that contain swear word
                  obscenities.
                </p>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSecondChecked}
                  onChange={(event, newValue) => setSecondChecked(newValue)}
                  color="primary"
                />
              }
              label={
                <p
                  style={{
                    opacity: 0.8,
                    fontSize: "0.65rem",
                    margin: 0,
                    padding: 0,
                    fontWeight: "lighter",
                  }}
                >
                  I am authorised to distribute this music to stores and
                  territories I select.
                </p>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isThirdChecked}
                  onChange={(event, newValue) => setThirdChecked(newValue)}
                  color="primary"
                />
              }
              label={
                <p
                  style={{
                    opacity: 0.8,
                    fontSize: "0.65rem",
                    margin: 0,
                    padding: 0,
                    fontWeight: "lighter",
                  }}
                >
                  I'm not using any other artist's name in my name, song titles,
                  or album title, without their approval.
                </p>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFourthChecked}
                  onChange={(event, newValue) => setFourthChecked(newValue)}
                  color="primary"
                />
              }
              label={
                <p
                  style={{
                    opacity: 0.8,
                    fontSize: "0.65rem",
                    margin: 0,
                    padding: 0,
                    fontWeight: "lighter",
                  }}
                >
                  By pressing "Distribute to all music platforms" you initiate
                  the process of sending the music for approval review. This
                  action confirms your acknowledgment that the content adheres
                  to the required standards and guidelines. Approval allows the
                  distribution of the music across various platforms.
                </p>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} mt={3}>
        <GradientBtn
          disabled={
            !(
              isFirstChecked &&
              isSecondChecked &&
              isThirdChecked &&
              isFourthChecked
            )
          }
          onClick={handleConfirm}
        >
          Distribute
        </GradientBtn>
      </Grid>
      <LoadingModal open={loading.isShown} text={loading.message} />
    </Grid>
  );
};

export default Distribution;
