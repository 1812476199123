const initialState = [];

const fundraisingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FUNDRAISINGS":
      return action.payload;
    case "UPDATE_FUNDRAISING":
      const updatedFundraising = action.payload;
      const updatedFundraisings = state.map((fundraising) =>
        fundraising.id === updatedFundraising.id
          ? updatedFundraising
          : fundraising
      );
      return updatedFundraisings;

    default:
      return state;
  }
};

export default fundraisingsReducer;
