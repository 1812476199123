import { Grid, Skeleton, Stack } from "@mui/material";
const ItemsGridItemSkeleton = () => {
  return (
    <Grid container display={"flex"} flexDirection={"column"}>
      <Grid item display={"flex"} flexDirection={"row"}>
        <Skeleton
          sx={{ backgroundColor: "#B8B8B8" }}
          variant="circular"
          width={40}
          height={40}
        />
        <Skeleton
          sx={{
            backgroundColor: "#B8B8B8",
            flex: 1,
            marginLeft: "1rem",
          }}
          variant="text"
        />
      </Grid>
      <Grid item>
        <Skeleton
          sx={{
            backgroundColor: "#B8B8B8",
            flex: 1,
            borderRadius: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          variant="rounded"
          height={300}
        />
      </Grid>
      <Grid item>
        <Skeleton
          sx={{
            backgroundColor: "#B8B8B8",
            flex: 1,
          }}
          height={30}
          variant="text"
        />
        <Skeleton
          sx={{
            backgroundColor: "#B8B8B8",
            flex: 1,
          }}
          height={30}
          variant="text"
        />
      </Grid>
      {/* For other variants, adjust the size with `width` and `height` */}
    </Grid>
  );
};

export default ItemsGridItemSkeleton;
