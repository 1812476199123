import { useState, useEffect } from "react";
import { IconButton, Box } from "@mui/material";
import { InsertPhoto, Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import LazyImage from "@components/LazyImage";

import {
  addImageToIndexedDB,
  removeImageFromIndexedDB,
  getImageFromIndexedDB,
} from "@helpers/indexedDB/imageDB";
import { useDispatch } from "react-redux";
import { uploadFileToS3Bucket } from "@helpers/appsync/storage";
import LoadingModal from "@components/LoadingModal";

const VALID_FILE_TYPES = ["image/jpg", "image/jpeg"];

const REQUIRED_SIZE = 3000;

const DragAndDropImage = (props) => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({
    isShown: false,
    message: "Loading",
  });

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    setLoading({ isShown: true, message: "Loading Image" });

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        const img = new Image();
        img.src = reader.result;

        await new Promise((resolve) => {
          img.onload = () => {
            resolve();
          };
        });

        if (img.width !== REQUIRED_SIZE || img.height !== REQUIRED_SIZE) {
          setLoading({ isShown: false, message: "" });
          toast.info(
            `${file.name} is not a ${REQUIRED_SIZE} X ${REQUIRED_SIZE} size`
          );
          return;
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Resize image while maintaining aspect ratio
        canvas.width = REQUIRED_SIZE;
        canvas.height = REQUIRED_SIZE;
        ctx.drawImage(img, 0, 0, REQUIRED_SIZE, REQUIRED_SIZE);
        const resizedImage = canvas.toDataURL("image/jpeg");

        try {
          const originalCoverImageUri = await uploadFileToS3Bucket(file, false);
          dispatch({
            type: "SET_ART_ORIGINAL_COVER_IMAGE_FORM",
            payload: originalCoverImageUri,
          });
          const compressedCoverImageUri = await uploadFileToS3Bucket(
            file,
            true
          );
          dispatch({
            type: "SET_ART_COMPRESSED_COVER_IMAGE_FORM",
            payload: compressedCoverImageUri,
          });
          await addImageToIndexedDB(
            "artImageLink",
            file.name,
            file.type,
            resizedImage
          ); // add the canvas image
          setImage(resizedImage);
          props.onImage(resizedImage);
          toast.success(`${file.name} added successfully`);
          setLoading({ isShown: false, message: "" });
        } catch (error) {
          console.log("Failed to add image");
          setLoading({ isShown: false, message: "" });
        }
      };
    } else {
      console.log("Failed to add image");
      setLoading({ isShown: false, message: "" });
    }
  };

  const handleRemoveImage = async () => {
    try {
      setLoading({ isShown: true, message: "Deleting Image File" });
      await removeImageFromIndexedDB("artImageLink");
      setImage(null);
      props.onImage(null);
      setLoading({ isShown: false, message: "" });
    } catch (error) {
      console.log("Failed to remove image from IndexedDB");
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const result = await getImageFromIndexedDB("artImageLink");
        if (result && result.data) {
          setImage(result.data);
          props.onImage(result.data);
        }
      } catch (error) {
        console.log("Failed to open IndexedDB:", error);
      }
    };

    fetchImage();
  }, [props]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: image ? "100%" : "100%",
        width: image ? "100%" : "100%",
        border: "2px dashed grey",
        borderRadius: "10px",
        p: 3,
        backgroundColor: "transparent",
        position: "relative",
        overflow: "hidden",
      }}
      onDrop={(event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        const file = droppedFiles[0];
        if (file) {
          const fileType = file.type;
          if (fileType && VALID_FILE_TYPES.includes(fileType)) {
            handleFileSelect({ target: { files: [file] } });
          } else {
            toast.error("Invalid file type");
          }
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
    >
      {!image && (
        <>
          <IconButton>
            <label htmlFor="upload-file">
              <InsertPhoto color="secondary" fontSize="large" />
            </label>
            <input
              id="upload-file"
              type="file"
              accept="image/jpeg, image/jpg"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
          </IconButton>
          <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>
            Drag and drop your project image here
          </h4>
          <p>JPEG or JPG format is required.</p>
          <p style={{ opacity: 0.7 }}>
            (Image Size - {REQUIRED_SIZE} X {REQUIRED_SIZE})
          </p>
          <p style={{ opacity: 0.7 }}>(1:1)</p>
        </>
      )}
      {image && (
        <>
          <LazyImage
            className="media_img"
            src={image}
            alt="uploaded"
          ></LazyImage>
          <IconButton
            onClick={handleRemoveImage}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <Close color="error" />
          </IconButton>
        </>
      )}
      <LoadingModal open={loading.isShown} text={loading.message} />
    </Box>
  );
};

export default DragAndDropImage;
