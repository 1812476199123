import { useState, useEffect } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import {
  CloudUpload,
  MusicNote,
  Close,
  CloudDownload,
  MusicNoteOutlined,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  getFile,
  deleteFile,
  storeFile,
} from "../../../../helpers/indexedDB/songDB";
import { uploadFileToS3Bucket } from "@helpers/appsync/storage";
import LoadingModal from "@components/LoadingModal";
import { cutToPreview } from "@helpers/tools/songHelper";

const DragAndDropSong = ({
  currentFileUri,
  coverTitle,
  currentTrackSerial,
  onSong,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState({
    isShown: false,
    message: "Loading",
  });

  useEffect(() => {
    // Get the file using the getFile function
    if (currentFileUri.length > 0) {
      getFile(`${coverTitle}-${currentTrackSerial}`).then((fileFromDB) => {
        if (fileFromDB) {
          setFile(
            Object.assign(fileFromDB, {
              preview: URL.createObjectURL(fileFromDB),
            })
          );
        }
      });
    } else {
      setFile(null);
    }
  }, [currentFileUri]);

  const AUDIO_TYPES = ["audio/mp3", "audio/wav", "audio/mpeg"];
  const MAX_FILE_SIZE_BYTES = 60 * 1024 * 1024; // 60 MB in bytes

  const shortenFileName = (selectedFile) => {
    return selectedFile.name.length > 25
      ? selectedFile.name.slice(0, 25) + "..."
      : selectedFile.name;
  };

  const handleDownload = () => {
    if (file) {
      const link = document.createElement("a");
      link.href = file.preview;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSong = async (selectedFile) => {
    try {
      if (selectedFile) {
        const fileType = selectedFile.type;

        if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
          toast.error(
            `${shortenFileName(
              selectedFile
            )} exceeds the maximum file size of ${MAX_FILE_SIZE_BYTES}.`
          );
          return;
        }

        if (!AUDIO_TYPES.includes(fileType)) {
          toast.error(
            `${shortenFileName(selectedFile)} is not a valid file type.`
          );
          return;
        }

        setFile(
          Object.assign(selectedFile, {
            preview: URL.createObjectURL(selectedFile),
          })
        );
        setLoading({ isShown: true, message: "Adding Track File" });
        await storeFile(`${coverTitle}-${currentTrackSerial}`, selectedFile);
        const songFile = await getFile(`${coverTitle}-${currentTrackSerial}`);
        const fileUri = await uploadFileToS3Bucket(songFile, false);
        const previewdSongFile = await cutToPreview(songFile);
        const previewFileUri = await uploadFileToS3Bucket(
          previewdSongFile,
          false
        );
        onSong(fileUri, previewFileUri);
        setLoading({ isShown: false, message: "" });
        toast.success(`${shortenFileName(selectedFile)} added successfully!`);
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to add song");
    }
  };

  const handleRemoveFile = async () => {
    setLoading({ isShown: true, message: "Deleting Track File" });
    setFile(null);
    await deleteFile(`${coverTitle}-${currentTrackSerial}`); // Remove song from IndexedDB
    setLoading({ isShown: false, message: "" });
    onSong("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        border: "2px dashed grey",
        borderRadius: "10px",
        p: 3,
        backgroundColor: "transparent",
        position: "relative",
        overflow: "hidden",
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleSong(event.dataTransfer.files[0]);
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
    >
      {!file && (
        <>
          <label htmlFor="upload-song">
            <MusicNoteOutlined color="secondary" fontSize="large" />
          </label>
          <input
            id="upload-song"
            type="file"
            accept="audio/mp3, audio/wav, audio/mpeg"
            style={{ display: "none" }}
            onChange={(event) => handleSong(event.target.files[0])}
          />
          <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>
            Drag and drop your audio here
          </h4>
          <p> MP3/WAV format is required.</p>
          <p>(Max each file size: 60MB)</p>
        </>
      )}
      {file && (
        <>
          <Box
            key={file.name}
            sx={{
              display: "flex",
              alignItems: "center",
              my: 1,
            }}
          >
            <MusicNote fontSize="large" />
            <Typography size="lg" variant="subtitle1">
              {shortenFileName(file)}
            </Typography>
          </Box>
          <IconButton
            onClick={handleRemoveFile}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <Close color="error" />
          </IconButton>
          <IconButton onClick={handleDownload} title="Download">
            <CloudDownload color="primary" />
          </IconButton>
        </>
      )}
      <LoadingModal open={loading.isShown} text={loading.message} />
    </Box>
  );
};

export default DragAndDropSong;
