// styling
import styles from "./style.module.scss";

// components
import ItemsGridItem from "./ItemsGridItem";
import { Grid, Skeleton, Stack } from "@mui/material";
import ItemsGridItemSkeleton from "./ItemsGridItem/ItemsGridItemSkeleton";

const ItemsGrid = ({ variant, items, className }) => {
  const data = variant === "preview" ? items.slice(0, 8) : items;

  return (
    <div
      className={`${styles.grid} ${className ? className : ""} items-grid`}
      id="items"
    >
      {data.length > 0 ? (
        data.map((item, index) => {
          return <ItemsGridItem key={item.id} item={item} index={index} />;
        })
      ) : (
        <>
          <ItemsGridItemSkeleton />
          <ItemsGridItemSkeleton />
        </>
      )}
    </div>
  );
};

export default ItemsGrid;
