const initialState = {
  amountRaising: "",
  sharedPercentage: "",
  startRaisingDate: null,
  endRaisingDate: null,
  description: "",
};

const raiseFundsFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_PROJECT":
      return initialState;
    case "SET_PROJECT_SHARED_PERCENTAGE":
      return {
        ...state,
        sharedPercentage: action.payload,
      };
    case "SET_PROJECT_AMOUNT_RAISING":
      return {
        ...state,
        amountRaising: action.payload,
      };
    case "SET_PROJECT_START_RAISING_DATE":
      return {
        ...state,
        startRaisingDate: action.payload,
      };
    case "SET_PROJECT_END_RAISING_DATE":
      return {
        ...state,
        endRaisingDate: action.payload,
      };
    case "SET_PROJECT_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    default:
      return state;
  }
};

export default raiseFundsFormReducer;
