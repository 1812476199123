import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingIndicator from "./LoadingIndicator/LoadingIndicator";
import loadable from "@loadable/component";
import {
  RegistrationPhase,
  useRegistrationModalContext,
} from "@contexts/registrationModalContext";

// components
const Header = loadable(() => import("@components/Header"));
const Footer = loadable(() => import("@components/Footer"));
const MintModal = loadable(() => import("@components/MintModal"));
const RegistrationModal = loadable(() =>
  import("@components/RegistrationModal")
);

const AppLayout = ({ children }) => {
  const profile = useSelector((state) => state.profile);
  const { openRegistrationModal } = useRegistrationModalContext();

  useEffect(() => {
    if (profile?.id) {
      if (!profile?.username || !profile?.username?.length === 0) {
        openRegistrationModal(RegistrationPhase.USERNAME);
      } else if (!profile?.referrer || !profile?.referrer?.length === 0) {
        openRegistrationModal(RegistrationPhase.REFERRER);
      }
    }
  }, [profile]);

  return (
    <div>
      <Header />
      {children}
      <MintModal />
      <RegistrationModal />
      <Footer />
    </div>
  );
};

export default AppLayout;
