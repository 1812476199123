import styled from "styled-components/macro";

const StyledArtistCard = styled.div`
  .container {
    max-width: 940px;
  }

  .cover {
    height: 200px;
    background: url("${(props) => props.coverImage || "none"}") no-repeat center
      center / cover fixed;
  }

  .main {
    margin-top: -90px;
    border: 1px solid var(--divider);
    text-align: center;
  }
`;

export default StyledArtistCard;
