import { FFmpeg } from "@ffmpeg/ffmpeg";

/* The line `const ffmpeg = new FFmpeg();` creates a new instance of the FFmpeg class. FFmpeg is a
popular multimedia framework that can be used to handle audio and video files. By creating a new
instance of the FFmpeg class, you can use its methods and functionality to perform various
operations on multimedia files, such as cutting, converting, and encoding. */
const ffmpeg = new FFmpeg();

/**
 * The `cutToPreview` function takes an audio file, cuts a portion of it from 01:00 to 01:10, and
 * returns a new File object with the cut audio.
 * @param audioFile - The `audioFile` parameter is the input audio file that you want to cut to create
 * a preview. It should be a File object representing the audio file.
 * @returns The function `cutToPreview` returns a Promise that resolves with a new File object
 * containing the cut audio.
 */
export const cutToPreview = async (audioFile) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ffmpeg.load();

      // Read the input file and convert to Uint8Array
      const fileData = await readFileAsUint8Array(audioFile);
      // Read the input file
      await ffmpeg.writeFile(audioFile.name, fileData);

      // Cut the audio from 01:00 to 01:10 and output with the same format
      await ffmpeg.exec([
        "-i",
        audioFile.name,
        "-ss",
        "60",
        "-t",
        "10",
        `output_${audioFile.name}`,
      ]);

      // Read the output file
      const data = await ffmpeg.readFile(`output_${audioFile.name}`);

      // Create a new File object with the cut audio
      const newAudioFile = new File([data], `preview-${audioFile.name}`, {
        type: audioFile.type,
      });

      resolve(newAudioFile);
    } catch (error) {
      reject(error);
    } finally {
      ffmpeg.off();
    }
  });
};

/**
 * The function `readFileAsUint8Array` reads a file and returns its contents as a Uint8Array.
 * @param file - The `file` parameter is the file that you want to read as a `Uint8Array`. It should be
 * a `File` object, which represents a file selected by the user through an `<input type="file">`
 * element or obtained from a drag and drop operation.
 * @returns The `readFileAsUint8Array` function returns a promise that resolves to a `Uint8Array` if
 * the file is successfully read as an `ArrayBuffer`. If there is an error reading the file, the
 * promise is rejected with an error message.
 */
const readFileAsUint8Array = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result;
      if (arrayBuffer instanceof ArrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      } else {
        reject(new Error("Failed to read file as Uint8Array"));
      }
    };
    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };
    reader.readAsArrayBuffer(file);
  });
};
