import { Modal, Typography } from "@mui/material";
import styles from "./style.module.scss";
import Lottie from "lottie-react"; // Import the Lottie component
import loadingLottie from "../../assets/lottie/loading-lottie.json"; // Import your Lottie animation JSON file

const LoadingModal = ({ open, text, subText = "Please Remain Patient..." }) => {
  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.paper}>
        {/* Use the Lottie component to render the animation */}
        <Lottie
          animationData={loadingLottie} // Pass your animation JSON data here
          loop={true}
          style={{ width: "180px", height: "180px" }} // Set the width and height of the animation
        />
        <h5 sx={{ color: "white", opacity: 0.8, margin: 20 }}>{text}</h5>
        <p sx={{ color: "grey", marginTop: 5 }}>{subText}</p>
      </div>
    </Modal>
  );
};

export default LoadingModal;
