import { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArtistGrowth from "@components/ArtistGrowth";
import LatestAlbums from "@components/LatestAlbums";
import styles from "./style.module.scss";
import ItemsGrid from "@components/ItemsGrid";
import useProjects from "@hooks/useProjects";

const ArtistItems = ({ artist }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [artistProjects, setArtistProjects] = useState([]);
  const { projects } = useProjects("HERO");

  useEffect(() => {
    const fetchProjects = async () => {
      const filteredProjects = projects.filter(
        (project) => project.projectFundraiserId === artist.id
      );
      setArtistProjects(filteredProjects);
    };
    fetchProjects();
  }, [artist, projects]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const StyledTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
      backgroundColor: "var(--accent)",
    },
    "& .MuiTabs-flexContainer": {
      marginTop: "2vh",
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      justifySelf: "center",
      justifyContent: "center",
      "& button": {
        textTransform: "none",
        fontFamily: "var(--heading-font)",
        fontSize: "1rem",
        color: "var(--text-color)",
        "&.Mui-selected": {
          color: "var(--accent)",
        },
        "&.Mui-disabled": {
          opacity: 0.3,
        },
      },
    },
  });

  return (
    <div>
      <StyledTabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Projects" />
        <Tab label="Stats" />
      </StyledTabs>
      <div className={`${styles.details} ${styles.fadeIn}`}>
        {tabIndex === 0 && (
          <div className="container d-flex flex-column g-30">
            {artistProjects?.length > 0 ? (
              <ItemsGrid items={artistProjects} />
            ) : (
              <p style={{ textAlign: "center" }}>No Projects Yet.</p>
            )}
          </div>
        )}
        {tabIndex === 1 && (
          <div>
            <ArtistGrowth
              artistName={
                artist?.name?.length > 0
                  ? `${artist.name}`
                  : artist?.username?.length > 0
                  ? `${artist.username}`
                  : ""
              }
            />
            <LatestAlbums
              artistName={
                artist?.name?.length > 0
                  ? `${artist.name}`
                  : artist?.username?.length > 0
                  ? `${artist.username}`
                  : ""
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistItems;
