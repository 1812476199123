import {
  getExploreProjects,
  getSummerizedProject,
  getUserById,
} from "@helpers/appsync/api";
import { getImageFromS3 } from "@helpers/appsync/storage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "@assets/black_background.png";
import { onUpdateProject } from "@graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const useProjects = (cameFrom = "") => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const [loading, setLoading] = useState({ isShown: false, message: "" });

  useEffect(() => {
    /**
     * The function `processProject` takes a project object, retrieves additional data from external
     * sources, and returns the modified project object.
     * @returns The `processProject` function returns the `project` object.
     */
    const processProject = async (project) => {
      try {
        const fundraiser = await getUserById(project.projectFundraiserId);
        project.fundraiser = fundraiser;
        if (project?.art?.compressedCoverImage?.length > 0) {
          const coverImageFile = await getImageFromS3(
            project.art.compressedCoverImage
          );
          project.coverImageFile = coverImageFile;
        } else {
          project.coverImageFile = placeholder;
        }

        if (project?.fundraiser?.profileImage?.length > 0) {
          const profileImageFile = await getImageFromS3(
            project.fundraiser.profileImage
          );
          project.profileImageFile = profileImageFile;
        } else {
          project.profileImageFile = placeholder;
        }

        return project;
      } catch (err) {
        if (!project.coverImageFile) {
          project.coverImageFile = placeholder;
        }
        if (!project.profileImageFile) {
          project.profileImageFile = placeholder;
        }
        return project;
      }
    };

    /**
     * The function fetchProjects fetches projects, processes them, and dispatches them to be set as
     * the projects state.
     */
    const fetchProjects = async () => {
      try {
        const fetchedProjects = await getExploreProjects();
        const processedProjects = await Promise.all(
          fetchedProjects.map(processProject)
        );
        dispatch({ type: "SET_PROJECTS", payload: processedProjects });
      } catch (err) {
        console.log(err);
      }
    };

    setLoading({
      isShown: true,
      message: "Loading",
    });
    if (projects == null || projects?.length === 0 || cameFrom === "HERO") {
      fetchProjects();
    }
    setLoading({ isShown: false, message: "" });
  }, [dispatch]);

  useEffect(() => {
    let updateSubscription;

    /**
     * The function `subscribeToUpdates` subscribes to updates on a project and dispatches an action
     * with the updated project details.
     */
    const subscribeToUpdates = async () => {
      try {
        updateSubscription = API.graphql(
          graphqlOperation(onUpdateProject)
        ).subscribe({
          next: async (eventData) => {
            const subscribedProject = eventData.value.data.onUpdateProject;
            try {
              // Fetch the updated project details
              const updatedProject = await getUpdatedProject(
                subscribedProject.id
              );

              dispatch({ type: "UPDATE_PROJECT", payload: updatedProject });
            } catch (error) {}
          },
        });
      } catch (error) {
        console.log("Error subscribing to updates:", error);
      }
    };

    const getUpdatedProject = async (id) => {
      try {
        const updatedProject = await getSummerizedProject(id);
        return updatedProject;
      } catch (error) {
        throw error; // Rethrow the error to handle it further if needed
      }
    };

    subscribeToUpdates();

    return () => {
      // Unsubscribe from the subscription when the component unmounts
      if (updateSubscription) {
        updateSubscription.unsubscribe();
      }
    };
  }, []);

  return { projects, loading };
};

export default useProjects;
