// components
import { NavLink } from "react-router-dom";

// assets
import logo from "@assets/white-name-transparent.webp";

const Logo = () => {
  return (
    <NavLink className="logo d-inline-flex align-items-center" to="/">
      <img className="logo_img" src={logo} alt="Probal" />
      {/* <span className="logo_text h4">Pro Capital</span> */}
    </NavLink>
  );
};

export default Logo;
