const initialState = [];

const releasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RELEASES":
      return action.payload;
    case "UPDATE_RELEASE":
      const updatedRelease = action.payload;
      const updatedReleases = state.map((release) =>
        release.id === updatedRelease.id ? updatedRelease : release
      );
      return updatedReleases;

    default:
      return state;
  }
};

export default releasesReducer;
