// components
import Fade from "@mui/material/Fade";
import Lottie from "lottie-react";

// styling
import styles from "./style.module.scss";

// utils
import { memo } from "react";

import logo from "@assets/logo-white.png";
// assets
import preloader from "@assets/lottie/preloader.json";
import Avatar from "@ui/Avatar";

const LoadingScreen = ({ visible }) => {
  return (
    <div className={styles.loading}>
      <Fade in={visible} timeout={1000}>
        <div className="loading-screen">
          <Lottie className="lottie" animationData={preloader} />
          <Avatar src={logo} size="sm" alt="Probal" />
          <h5>© Probal</h5>
        </div>
      </Fade>
    </div>
  );
};

export default memo(LoadingScreen);
