// GA
import ReactGA from "react-ga4";

// styling
import "./style.scss";

// libs styles
import "swiper/css";
import "swiper/css/effect-fade";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";

// utils
import { lazy, Suspense, useState } from "react";
import { preventDefault } from "@utils/helpers";

import { getSummerizedProject, isUserArtist } from "@helpers/appsync/api";

// hooks
import { useEffect } from "react";

// context
import { MintModalContextAPI } from "@contexts/mintModalContext";
import { RegistrationModalContextAPI } from "@contexts/registrationModalContext";
import { SidebarContextAPI } from "@contexts/sidebarContext";
import { PathProviderAPI } from "@contexts/pathContext";

// components
import LoadingScreen from "@components/LoadingScreen/LoadingScreen";
import AppLayout from "@components/AppLayout";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DrawerDashboardLayout from "@components/DrawerDashboardLayout";
import MusicSubmission from "@pages/Form/MusicSubmission";
import Artist from "@pages/Details/Artist";

//wallet
import { activateNewUserUsdc } from "@helpers/api/contract";
import {
  isUsdcAssociatedToAccount,
  getCurrentHbarBalance,
} from "@helpers/api/hedera";
import useMagicWallet from "@hooks/wallet/useMagicWallet";
import { HBAR_DECIMAL_NUM } from "@constants/numbers";

// pages
// const Home = lazy(() => import("@pages/Home"));
const Home2 = lazy(() => import("@pages/Home/Home2"));
const HomeSubmit = lazy(() => import("@pages/Home/HomeSubmit"));
const Explore = lazy(() => import("@pages/Explore/Explore"));
const Holdings = lazy(() => import("@pages/Dashboard/Holdings"));
const Fundraising = lazy(() => import("@pages/Dashboard/Fundraising"));
const Wallet = lazy(() => import("@pages/Wallet"));
const RiskFactors = lazy(() => import("@pages/Others/RiskFactors"));
const PrivacyPolicy = lazy(() => import("@pages/Others/PrivacyPolicy"));
const AdminDashboard = lazy(() => import("@pages/Admin/AdminDashboard"));
const TermsOfService = lazy(() => import("@pages/Others/TermsOfService"));
const ExploreGrid = lazy(() => import("@pages/Explore/ExploreGrid"));
const ProjectDetails = lazy(() => import("@pages/Details/ProjectDetails"));
const User = lazy(() => import("@pages/Details/User"));
const Profile = lazy(() => import("@pages/Profile"));
const FAQ = lazy(() => import("@pages/Others/FAQ"));
const Ranking = lazy(() => import("@pages/Others/Ranking"));
const Activity = lazy(() => import("@pages/Activity"));
const Connect = lazy(() => import("@pages/Connect"));
const Releases = lazy(() => import("@pages/Releases"));
const About = lazy(() => import("@pages/Others/About"));
const Team = lazy(() => import("@pages/Others/Team"));
const BlogSidebar = lazy(() => import("@pages/Blog/BlogSidebar"));
const BlogGrid = lazy(() => import("@pages/Blog/BlogGrid"));
const Post = lazy(() => import("@pages/Blog/Post"));
const Contacts = lazy(() => import("@pages/Others/Contacts"));
const PageNotFound = lazy(() => import("@pages/Others/PageNotFound"));
const RaiseFunds = lazy(() => import("@pages/Form/RaiseFunds"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#00d107", // your desired primary color
    },
    secondary: {
      main: "#969696", // your desired secondary color
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const [isArtist, setIsArtist] = useState(false);
  const { associateUsdcViaMagicWallet } = useMagicWallet();
  const walletType = useSelector((state) => state.walletType);

  useEffect(() => {
    const fetchIsArtist = async () => {
      try {
        if (profile?.id) {
          const result = await isUserArtist(profile.id);
          setIsArtist(result);
          const { id } = profile;
          const updatedProfile = { id, isArtist: result };
          dispatch({ type: "UPDATE_PROFILE", payload: updatedProfile });
        } else {
          setIsArtist(false);
        }
      } catch (err) {
        setIsArtist(false);
      }
    };

    fetchIsArtist();
  }, [profile?.id]);

  const isWalletConnected = () => {
    return profile?.id.length > 0;
  };

  useEffect(() => {
    if (!isWalletConnected()) {
      localStorage.clear();
      for (let key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          localStorage.removeItem(key);
        }
      }
    }
  }, []);

  const gaKey = process.env.REACT_APP_PUBLIC_GA;
  gaKey && ReactGA.initialize(gaKey);

  useEffect(() => {
    const associateUsdc = async () => {
      try {
        if (profile?.publicAddress && walletType === "Magic") {
          const isUsdcAssociated = await isUsdcAssociatedToAccount(
            profile.publicAddress
          );

          if (!isUsdcAssociated) {
            const hbarBalance = await getCurrentHbarBalance(
              profile.publicAddress
            );
            if (hbarBalance / HBAR_DECIMAL_NUM <= 1) {
              await activateNewUserUsdc(profile.publicAddress);
            }
            const associateUsdcReceipt = await associateUsdcViaMagicWallet(
              profile.publicAddress
            );
            if (associateUsdcReceipt) {
              toast.info("Activated Wallet!");
            } else {
              throw new Error();
            }
          }
        }
      } catch (e) {
        console.log(e);
        toast.info("Couldn't Activate Wallet, Please Refresh");
      }
    };

    associateUsdc();
  }, [profile?.id]);

  return (
    <ThemeProvider theme={theme}>
      <RegistrationModalContextAPI>
        <MintModalContextAPI>
          <SidebarContextAPI>
            <PathProviderAPI>
              <Suspense fallback={<LoadingScreen visible />}>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      !isWalletConnected() ? (
                        <Connect />
                      ) : isArtist ? (
                        <DrawerDashboardLayout>
                          <Releases />
                        </DrawerDashboardLayout>
                      ) : (
                        <DrawerDashboardLayout>
                          <Holdings />
                        </DrawerDashboardLayout>
                      )
                    }
                  />
                  <Route
                    path="/releases"
                    element={
                      isWalletConnected() ? (
                        <DrawerDashboardLayout>
                          <Releases />
                        </DrawerDashboardLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/fundraising"
                    element={
                      isWalletConnected() ? (
                        <DrawerDashboardLayout>
                          <Fundraising />
                        </DrawerDashboardLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/wallet"
                    element={
                      isWalletConnected() ? (
                        <DrawerDashboardLayout>
                          <Wallet />
                        </DrawerDashboardLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />

                  <Route
                    path="/free"
                    element={
                      <AppLayout>
                        <HomeSubmit />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="/holdings"
                    element={
                      isWalletConnected() ? (
                        <DrawerDashboardLayout>
                          <Holdings />
                        </DrawerDashboardLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/holdings"
                    element={
                      isWalletConnected() ? (
                        <DrawerDashboardLayout>
                          <Holdings />
                        </DrawerDashboardLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <AppLayout>
                        <Home2 />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="/explore"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <Explore />
                        </AppLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/project/:name"
                    element={
                      <AppLayout>
                        <ProjectDetails />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="/user/:username"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <User />
                        </AppLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/artist/:username"
                    element={
                      <AppLayout>
                        <Artist />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <Profile />
                        </AppLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/raise-funds"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <RaiseFunds />
                        </AppLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/music-submission"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <MusicSubmission />
                        </AppLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  {/* <Route
                  path="/faq"
                  element={
                    <AppLayout>
                      <FAQ />
                    </AppLayout>
                  }
                />
                <Route
                  path="/ranking"
                  element={
                    <AppLayout>
                      <Ranking />
                    </AppLayout>
                  }
                />
                <Route
                  path="/activity"
                  element={
                    <AppLayout>
                      <Activity />
                    </AppLayout>
                  }
                /> */}
                  <Route
                    path="/login"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <Profile />
                        </AppLayout>
                      ) : (
                        <Connect />
                      )
                    }
                  />
                  <Route
                    path="/privacy-policy"
                    element={
                      <AppLayout>
                        <PrivacyPolicy />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="/risk-factors"
                    element={
                      <AppLayout>
                        <RiskFactors />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="/admin/"
                    element={
                      isWalletConnected() ? (
                        <AppLayout>
                          <AdminDashboard />
                        </AppLayout>
                      ) : (
                        <AppLayout>
                          <PageNotFound />
                        </AppLayout>
                      )
                    }
                  />
                  <Route
                    path="/terms-of-service"
                    element={
                      <AppLayout>
                        <TermsOfService />
                      </AppLayout>
                    }
                  />
                  {/* <Route
                  path="/about"
                  element={
                    <AppLayout>
                      <About />
                    </AppLayout>
                  }
                />
                <Route
                  path="/team"
                  element={
                    <AppLayout>
                      <Team />
                    </AppLayout>
                  }
                />
                <Route
                  path="/blog-sidebar"
                  element={
                    <AppLayout>
                      <BlogSidebar />
                    </AppLayout>
                  }
                />
                <Route
                  path="/blog-grid"
                  element={
                    <AppLayout>
                      <BlogGrid />
                    </AppLayout>
                  }
                />
                <Route
                  path="/post"
                  element={
                    <AppLayout>
                      <Post />
                    </AppLayout>
                  }
                /> */}
                  <Route
                    path="/contacts"
                    element={
                      <AppLayout>
                        <Contacts />
                      </AppLayout>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <AppLayout>
                        <PageNotFound />
                      </AppLayout>
                    }
                  />
                </Routes>
              </Suspense>
            </PathProviderAPI>
          </SidebarContextAPI>
        </MintModalContextAPI>
      </RegistrationModalContextAPI>
    </ThemeProvider>
  );
};

export default App;
