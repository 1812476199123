import { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import GradientBtn from "@ui/GradientBtn";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  formattedAWSDate,
  unformattedAWSDate,
} from "@helpers/tools/dateHelper";
import dayjs from "dayjs";
import styles from "./style.module.scss";
import { MusicType } from "@constants/strings";
import musicplatforms from "@assets/icons/musicplatforms.webp";
import LazyImage from "@components/LazyImage";
import DragAndDropImage from "@components/FundraisingSubmission/UploadProjectDetails/DragAndDropImage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ArrowBackIosNewTwoTone,
  CalendarTodayOutlined,
} from "@mui/icons-material";

const ReleaseInfo = ({ onNext, onBack }) => {
  const dispatch = useDispatch();

  const musicTypesOptions = Object.values(MusicType);
  const [coverImage, setCoverImage] = useState("");

  const handleImage = (imageFromChild) => {
    setCoverImage(imageFromChild);
  };
  const [isChecked, setChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const artForm = useSelector((state) => state.artForm);
  const { title, musicType, releaseDate } = artForm;

  const [minStartReleaseDate, setMinStartReleaseDate] = useState(
    dayjs().add(1, "month").toDate()
  );

  useEffect(() => {
    if (!releaseDate) {
      dispatch({
        type: "SET_ART_RELEASE_DATE_FORM",
        payload: formattedAWSDate(minStartReleaseDate),
      });
    } else {
      setMinStartReleaseDate(releaseDate);
    }
  }, [artForm]);

  const handleMusicTypeChange = (newValue) => {
    dispatch({
      type: "SET_ART_MUSIC_TYPE_FORM",
      payload: newValue,
    });
  };

  const handleReleaseDateChange = (value) => {
    const awsDateFormatted = formattedAWSDate(value);

    console.log(awsDateFormatted);
    dispatch({ type: "SET_ART_RELEASE_DATE_FORM", payload: awsDateFormatted });
  };

  const handleArtTitleChange = (event) => {
    dispatch({ type: "SET_ART_TITLE_FORM", payload: event.target.value });
  };

  const handleNextClick = () => {
    if (
      title === "" ||
      releaseDate === null ||
      musicType === null ||
      coverImage === "" ||
      coverImage === null
    ) {
      toast.error("Please fill all the fields and ensure valid input.");
    } else {
      onNext();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} marginTop={1} marginBottom={1}>
        <Button
          onClick={onBack}
          sx={{ fontFamily: "var(--heading-font)", textTransform: "none" }}
          startIcon={<ArrowBackIosNewTwoTone />}
          color="primary"
        >
          Go Back
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <DragAndDropImage onImage={handleImage} />
      </Grid>
      <Grid item xs={12} sm={12} md={8} mb={2}>
        <Grid container spacing={1} style={{ width: "90%", margin: "auto" }}>
          <Grid item xs={12} sm={12} md={6}>
            <p className={styles.input_title}>Title:</p>
            <TextField
              placeholder="Name"
              value={title}
              onChange={handleArtTitleChange}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": { color: "white" },
                "& label": { color: "grey" },
                "& fieldset": { borderColor: "grey" },
                "&:hover fieldset": { borderColor: "grey" },
                "&.Mui-focused fieldset": { borderColor: "grey" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p className={styles.input_title}>Music Type:</p>
            <Autocomplete
              placeholder="Enter Type"
              sx={{
                width: "100%",
                "& .MuiInputBase-input": { color: "white" },
                "& label": { color: "grey" },
                "& fieldset": { borderColor: "grey" },
                "&:hover fieldset": { borderColor: "grey" },
                "&.Mui-focused fieldset": { borderColor: "grey" },
              }}
              options={musicTypesOptions}
              defaultValue={"Single"}
              value={musicType}
              onChange={(event, newValue) => handleMusicTypeChange(newValue)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Press to choose" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p className={styles.input_title}>Release Date:</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                minDate={dayjs(minStartReleaseDate)}
                value={dayjs(releaseDate)}
                onChange={handleReleaseDateChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Press to choose" />
                )}
                components={{
                  OpenPickerIcon: () => (
                    <IconButton className="custom-calendar-icon">
                      <CalendarTodayOutlined style={{ color: "grey" }} />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { color: "white" },
                  "& label": { color: "grey" },
                  "& fieldset": { borderColor: "grey" },
                  "&:hover fieldset": { borderColor: "grey" },
                  "&.Mui-focused fieldset": { borderColor: "grey" },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p className={styles.input_title}>On Music Platforms:</p>
            <LazyImage
              className={styles.platforms}
              src={musicplatforms}
              alt="Music Platforms"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} mt={5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label={
                <p
                  style={{
                    opacity: 0.8,
                    fontSize: "0.65rem",
                    margin: 0,
                    padding: 0,
                    fontWeight: "lighter",
                  }}
                >
                  By approving these details, you confirm that you own the
                  rights to the music, including the title, music type, and
                  release date, and any associated content. You grant the right
                  to use, distribute, and license the music in accordance with
                  the terms of our agreement. This includes copyright,
                  production, and any related intellectual property rights.
                </p>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} mt={5}>
        <GradientBtn
          disabled={
            !isChecked ||
            title === "" ||
            !musicType ||
            releaseDate == null ||
            coverImage === "" ||
            coverImage === null
          }
          onClick={handleNextClick}
        >
          Next
        </GradientBtn>
      </Grid>
    </Grid>
  );
};

export default ReleaseInfo;
