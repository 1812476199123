const initialState = [];

const holdingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HOLDINGS":
      return action.payload;
    case "UPDATE_HOLDING":
      const updatedHolding = action.payload;
      const updatedHoldings = state.map((holding) =>
        holding.id === updatedHolding.id ? updatedHolding : holding
      );
      return updatedHoldings;

    default:
      return state;
  }
};

export default holdingsReducer;
