import Database, { STORE_IMAGE_NAME } from "./singleton-db";
/**
 * The function `getImageFromIndexedDB` retrieves an image from an IndexedDB database using a specified
 * key.
 * @param key - The `key` parameter in the `getImageFromIndexedDB` function is used to specify the key
 * of the image you want to retrieve from the IndexedDB. The key is used to uniquely identify the image
 * in the object store.
 * @returns The function `getImageFromIndexedDB` returns a promise that resolves with the result of the
 * `store.get(key)` operation in IndexedDB.
 */

const getImageFromIndexedDB = async (key) => {
  try {
    const db = await Database.open();
    const tx = db.transaction(STORE_IMAGE_NAME, "readonly");
    const store = tx.objectStore(STORE_IMAGE_NAME);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onerror = () => {
        reject(null);
      };

      request.onsuccess = () => {
        const result = request.result;
        resolve(result);
      };
    });
  } catch (error) {
    console.log("Failed to open IndexedDB:", error);
  }
};

/**
 * The function `removeImageFromIndexedDB` removes an image from an IndexedDB database using a given
 * key.
 * @param key - The `key` parameter in the `removeImageFromIndexedDB` function is the key of the image
 * that you want to remove from the IndexedDB. It is used to identify the specific image entry that
 * needs to be deleted from the object store.
 * @returns The function `removeImageFromIndexedDB` returns a promise.
 */
const removeImageFromIndexedDB = async (key) => {
  try {
    const db = await Database.open();
    const tx = db.transaction(STORE_IMAGE_NAME, "readwrite");
    const store = tx.objectStore(STORE_IMAGE_NAME);
    const request = store.delete(key);

    return new Promise((resolve, reject) => {
      request.onerror = () => {
        reject(null);
      };

      request.onsuccess = () => {
        const result = request.result;
        resolve(result);
      };
    });
  } catch (error) {
    console.log("Failed to open IndexedDB:", error);
  }
};

/**
 * The function `addImageToIndexedDB` adds an image to an IndexedDB database with the specified id,
 * name, type, and image data.
 * @param id - The `id` parameter is the unique identifier for the image. It could be a number or a
 * string that uniquely identifies the image in the IndexedDB database.
 * @param name - The `name` parameter represents the name of the image.
 * @param type - The "type" parameter refers to the type of the image file, such as "jpeg", "png", etc.
 * @param image - The `image` parameter in the `addImageToIndexedDB` function represents the actual
 * image data that you want to store in the IndexedDB. It could be a Blob, File, or any other data type
 * that represents an image.
 * @returns The function `addImageToIndexedDB` returns a promise.
 */
const addImageToIndexedDB = async (id, name, type, image) => {
  try {
    const db = await Database.open();
    const tx = db.transaction(STORE_IMAGE_NAME, "readwrite");
    const store = tx.objectStore(STORE_IMAGE_NAME);
    const request = store.put({
      id: id,
      name: name,
      type: type,
      data: image,
    });

    return new Promise((resolve, reject) => {
      request.onerror = () => {
        reject(null);
      };

      request.onsuccess = () => {
        const result = request.result;
        resolve(result);
      };
    });
  } catch (error) {
    console.log("Failed to open IndexedDB:", error);
  }
};

export { addImageToIndexedDB, removeImageFromIndexedDB, getImageFromIndexedDB };
