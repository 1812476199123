// styling
import styles from "./style.module.scss";
import usdc from "@assets/icons/usdc.webp";

// components
import LazyImage from "@components/LazyImage";
import { NavLink, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import Avatar from "@ui/Avatar";
import Spring from "@components/Spring";
import Box from "@mui/material/Box";
//progress bar
import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";

// utils
import dayjs from "dayjs";

// hooks
import { useMintModalContext } from "@contexts/mintModalContext";

import { useDispatch, useSelector } from "react-redux";
import placeholder from "@assets/avatar_placeholder.webp";
import { useState } from "react";
import { getImageFromS3 } from "@helpers/appsync/storage";
import { useEffect } from "react";
import { Verified } from "@mui/icons-material";
import { formattedAWSDate } from "@helpers/tools/dateHelper";

const ItemsGridItem = ({ item, index }) => {
  const {
    id,
    amountRaised,
    amountRaising,
    endRaisingDate,
    profileImageFile,
    coverImageFile,
    art: {
      title,
      artist: {
        user: { username, name },
      },
    },
  } = item;
  const { openMintModal } = useMintModalContext();

  // Calculate the time left from now to the end raising date
  const now = dayjs();
  const endRaisingDateTime = dayjs(endRaisingDate);
  const timeLeftMilliseconds = endRaisingDateTime.diff(now, "milliseconds");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector((state) => state.profile);
  const [isProjectEnded, setIsProjectEnded] = useState(false);

  const [isCurrentProfileProjectCreator, setIsCurrentProfileProjectCreator] =
    useState(false);

  const isWalletConnected = () => {
    return profile?.id && profile?.publicAddress;
  };

  useEffect(() => {
    const checkIsProjectEnd = () => {
      const convertedEndDate = formattedAWSDate(endRaisingDate);
      const currentDate = new Date();
      setIsProjectEnded(
        currentDate >= convertedEndDate || amountRaised === amountRaising
      );
    };

    const checkIsCurrentProfileProjectCreator = () => {
      setIsCurrentProfileProjectCreator(username == profile?.username);
    };
    checkIsCurrentProfileProjectCreator();
    checkIsProjectEnd();
  }, [item]);

  const calcRaisingPercentage = () => {
    const raised = (amountRaised / amountRaising) * 100;
    return parseFloat(
      Number(raised).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    );
  };

  const openMintModalHandler = () => {
    if (profile) {
      dispatch({ type: "SET_CLICKED_PROJECT_ID", payload: id });
      openMintModal();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className={`${styles.wrapper} border-hover  bg-primary`}>
      <div
        style={{ marginTop: "2px" }}
        className="user d-flex align-items-center g-10"
      >
        <Avatar
          src={profileImageFile}
          alt={name || "Profile Image"}
          size="sm"
          isVerified={false}
        />
        <NavLink
          className="text-sm text-bold text-light link-hover link-hover--invert"
          to={"/artist/" + username.replace(/\s/g, "-")}
        >
          <div className="d-flex align-items-center">
            <p style={{ fontSize: "medium" }}>@{username}</p>
            <Verified
              fontSize="small"
              sx={{ color: "#42A5F5", marginLeft: "5px" }}
            />
          </div>
        </NavLink>
      </div>
      <div className={`${styles.media} square border-10  `}>
        <NavLink to={"/project/" + title.replace(/\s/g, "-")} state={{ id }}>
          <LazyImage src={coverImageFile} alt={title || "Cover Image"} />
        </NavLink>
      </div>
      <div>
        <div className={styles.main_amount_raised}>
          <LazyImage className={styles.usdc_logo} src={usdc} alt="USDC" />
          <h6>{`${amountRaised}/${amountRaising}`}</h6>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "0.2rem",
            marginBottom: "1rem",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              sx={{
                height: 10,
                borderRadius: 5,
              }}
              color="primary"
              variant="determinate"
              value={calcRaisingPercentage()}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <p
              className={`${styles.main_percentages}`}
            >{`${calcRaisingPercentage()}%`}</p>
          </Box>
        </Box>
      </div>
      <div className={styles.main}>
        <div className="d-flex align-items-center justify-content-between g-10">
          <NavLink
            className="h4 text-overflow link-hover"
            to={"/project/" + title.replace(/\s/g, "-")}
            state={{ id }}
          >
            {title}
          </NavLink>
        </div>

        {!isProjectEnded && (
          <div className={`${styles.main_countdown} text-sm text-bold`}>
            <div className="d-flex g-10">
              <Countdown
                date={Date.now() + timeLeftMilliseconds}
                renderer={({ days, hours, minutes }) => {
                  return (
                    <p
                      style={{
                        color: "white",
                        fontSize: "larger",
                        fontWeight: 200,
                        opacity: 0.9,
                      }}
                    >
                      🔥 {days}d {hours}h {minutes}m
                    </p>
                  );
                }}
              />
            </div>
          </div>
        )}
        {!isProjectEnded && !isCurrentProfileProjectCreator && profile && (
          <div className="d-flex justify-content-between">
            <button
              style={{ cursor: "pointer" }}
              className={`${styles.main_btn} text-accent text-md link-hover link-hover--invert`}
              onClick={openMintModalHandler}
            >
              Mint
            </button>
            {/* <Like count={likes} isLiked={isLiked} /> */}
          </div>
        )}

        {!isProjectEnded && !isCurrentProfileProjectCreator && !profile && (
          <div className="d-flex justify-content-between">
            <button
              style={{ cursor: "pointer" }}
              className={`${styles.main_btn} text-accent text-md link-hover link-hover--invert`}
              onClick={openMintModalHandler}
            >
              Connect
            </button>
            {/* <Like count={likes} isLiked={isLiked} /> */}
          </div>
        )}

        {isProjectEnded && (
          <div style={{ opacity: 0.6, marginBottom: 10 }}>
            <h6>Completed!</h6>
          </div>
        )}
        {(isProjectEnded || isCurrentProfileProjectCreator) && (
          <div className="d-flex justify-content-between">
            <NavLink
              to={"/project/" + title.replace(/\s/g, "-")}
              state={{ id }}
            >
              <button
                style={{ cursor: "pointer" }}
                className={`${styles.main_btn} text-accent text-md link-hover link-hover--invert`}
              >
                See details
              </button>
            </NavLink>
            {/* <Like count={likes} isLiked={isLiked} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemsGridItem;
