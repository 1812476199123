const DB_NAME = "my-db";
const DB_VERSION = 1;
export const STORE_IMAGE_NAME = "images";
export const STORE_FILE_NAME = "files";
export const STORE_SONG_NAME = "songs";

class Database {
  static instance;

  constructor() {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.log("IndexedDB error: ", event.target.error);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const objectImageStore = db.createObjectStore(STORE_IMAGE_NAME, {
        keyPath: "id",
      });
      objectImageStore.createIndex("name", "name", { unique: false });
      const objectSongStore = db.createObjectStore(STORE_SONG_NAME, {
        keyPath: "id",
      });
      objectSongStore.createIndex("name", "name", { unique: false });
    };

    request.onsuccess = () => {
      this.db = request.result;
    };
  }

  static getInstance() {
    if (!Database.instance) {
      Database.instance = new Database();
    }
    return Database.instance;
  }

  open() {
    return new Promise((resolve, reject) => {
      if (this.db) {
        resolve(this.db);
      } else {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
          console.log("IndexedDB error: ", event.target.error);
          reject(event.target.error);
        };

        request.onsuccess = () => {
          this.db = request.result;
          resolve(this.db);
        };
      }
    });
  }
}

export default Database.getInstance();
